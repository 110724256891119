import api from './api';
function price(product) {
    var _a, _b, _c, _d;
    return ((_a = product === null || product === void 0 ? void 0 : product.price) === null || _a === void 0 ? void 0 : _a.type) === 'range' ? (_c = (_b = product === null || product === void 0 ? void 0 : product.price) === null || _b === void 0 ? void 0 : _b.min) === null || _c === void 0 ? void 0 : _c.sales : (_d = product === null || product === void 0 ? void 0 : product.price) === null || _d === void 0 ? void 0 : _d.sales;
}
function generateDim102(product) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let dimension102 = [
        (product === null || product === void 0 ? void 0 : product.isOnlineExclusive) && 'onlineExclusive',
        (product === null || product === void 0 ? void 0 : product.isLimitedEdition) && 'limitedEdition',
        (product === null || product === void 0 ? void 0 : product.isBoutiqueExclusive) && 'boutiqueExclusive',
        (product === null || product === void 0 ? void 0 : product.isComingSoon) == 2 && 'comingSoon',
        ((_a = product === null || product === void 0 ? void 0 : product.availability) === null || _a === void 0 ? void 0 : _a.isOutOfStock) && 'outOfStock',
        ((_b = product === null || product === void 0 ? void 0 : product.availability) === null || _b === void 0 ? void 0 : _b.preorder) && 'preorder',
        (product === null || product === void 0 ? void 0 : product.unlock) && 'membersOnly',
        ((_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.productTagAnalytics) && ((_e = (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.productTagAnalytics) === null || _e === void 0 ? void 0 : _e.toLowerCase()),
        ((_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.comunicationTagAnalytics) && ((_h = (_g = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.comunicationTagAnalytics) === null || _h === void 0 ? void 0 : _h.toLowerCase()),
    ];
    return dimension102.filter((value) => value).length > 0 ? dimension102.filter((value) => value).join('-') : 'none';
}
function generateDim102Cart(product) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    let dimension102 = [
        ((_a = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _a === void 0 ? void 0 : _a.isOnlineExclusive) == 'true' && 'onlineExclusive',
        ((_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.isLimitedEdition) == 'true' && 'limitedEdition',
        ((_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.isBoutiqueExclusive) == 'true' && 'boutiqueExclusive',
        ((_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.isComingSoon) == 'true' && 'comingSoon',
        ((_e = product === null || product === void 0 ? void 0 : product.availability) === null || _e === void 0 ? void 0 : _e.isOutOfStock) && 'outOfStock',
        ((_f = product === null || product === void 0 ? void 0 : product.availability) === null || _f === void 0 ? void 0 : _f.preorder) && 'preorder',
        ((_g = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.unlock) == 'true' && 'membersOnly',
        ((_h = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.productTagAnalytics) && ((_k = (_j = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _j === void 0 ? void 0 : _j.productTagAnalytics) === null || _k === void 0 ? void 0 : _k.toLowerCase()),
        ((_l = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _l === void 0 ? void 0 : _l.comunicationTagAnalytics) && ((_o = (_m = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _m === void 0 ? void 0 : _m.comunicationTagAnalytics) === null || _o === void 0 ? void 0 : _o.toLowerCase()),
    ];
    return dimension102.filter((value) => value).length > 0 ? dimension102.filter((value) => value).join('-') : 'none';
}
function priceValue(price) {
    var _a;
    return (price === null || price === void 0 ? void 0 : price.type) === 'range' ? (_a = price === null || price === void 0 ? void 0 : price.min) === null || _a === void 0 ? void 0 : _a.sales : price === null || price === void 0 ? void 0 : price.sales;
}
let timer;
let count = 0;
if (document.readyState === 'complete' || window.dataLayer) {
    window.dataLayer.push({ event: 'dataLayerStatus', status: 'ready' });
}
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        // call on next available tick
        setTimeout(fn, 1);
    }
    else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
function push(obj) {
    var _a, _b;
    count++;
    if (((_a = navigator.userAgent) === null || _a === void 0 ? void 0 : _a.toLowerCase().indexOf('bot')) > -1 || ((_b = navigator.userAgent) === null || _b === void 0 ? void 0 : _b.toLowerCase().indexOf('headless')) > -1) {
        // no GTM for crawler/headless
        return;
    }
    if (window.dataLayer) {
        clearTimeout(timer);
        if (obj.eventAction && !obj.eventLabel) {
            obj.eventLabel = 'undefined';
        }
        docReady(() => window.dataLayer.push(obj));
    }
    else if (!window.devmode) {
        if (count >= 5) {
            console.error('datalayer not ready', new Error().stack);
        }
        else {
            timer = setTimeout(() => push(obj), 100);
        }
    }
}
function checkoutStep(type) {
    switch (type) {
        case 'ShoppingBag':
            return 1;
        case 'Shipping&Delivery':
            return 2;
        case 'Payment':
            return 3;
        default:
            return '';
    }
}
function generateLabel(low, out, back) {
    let values = [];
    if (low) {
        values.push('low');
    }
    if (out) {
        values.push('out');
    }
    if (back) {
        values.push('back');
    }
    return values.join('/') + '-submit';
}
function generateDim98(sleeve_personalization, front_personalization, back_personalization) {
    const personalization = ((sleeve_personalization != '' && sleeve_personalization != undefined ? 's_t' : '') +
        '-' +
        (front_personalization != '' && front_personalization != undefined ? 'f_t' : '') +
        '-' +
        (back_personalization == 'MAXICOQ' ? 'b_l' : back_personalization != '' && back_personalization != undefined ? 'b_t' : ''))
        .replace('--', '-')
        .replace(/^\-/, '')
        .replace(/\-$/, '');
    return personalization == '' && sleeve_personalization != undefined && front_personalization != undefined && back_personalization != undefined ? 'none' : personalization;
}
const analytics = {
    /**
     * send general variables
     */
    sendGeneralVariables: () => {
        // console.info('_____ sendGeneralVariables', window.datalayerdata);
        push(window.datalayerdata);
    },
    /**
     * FLP Interaction link
     */
    flpClickLink: (pageId, i) => {
        push({ event: 'flp', eventCatetory: 'flp', eventActions: 'banner_interactions', eventLabel: pageId.replaceAll(' ', '_') + '-' + (i + 1) });
    },
    discoveryGridClickLink: (pageId, i) => {
        push({
            event: 'promotionClick',
            ecommerce: { promoClick: { promotions: [{ id: 'promo', name: pageId.replaceAll(' ', '_') + '-' + (i + 1), creative: 'flp', position: i + 1 }] } },
        });
    },
    flpPromotionView: (pageId, gridIndex) => {
        let promotions = [];
        for (let i = 0; i < gridIndex; i++) {
            promotions.push({ id: 'promo', name: pageId.replaceAll(' ', '_') + '-' + (i + 1), creative: 'flp', position: i + 1 });
        }
        push({ event: 'promotionView', ecommerce: { promoView: { promotions: promotions } } });
    },
    /**
     * PDP - Product Detail Page
     */
    productDetailPage: (product) => {
        var _a;
        // console.info('_____ productDetailPage');
        push({
            ecommerce: {
                detail: {
                    products: [
                        Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value }, product === null || product === void 0 ? void 0 : product.analyticsAttributes),
                    ],
                },
            },
        });
    },
    /**
     * PDP - Product Detail Page from PLP (with animations)
     */
    productDetailViewsWithTransitions: (product) => {
        var _a, _b, _c, _d;
        // console.info('_____ productDetailViewsWithTransitions', product);
        push({
            event: 'productDetailViews',
            ecommerce: {
                detail: {
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension102: generateDim102(product), 
                            //   !product?.isOnlineExclusive &&
                            //   !product?.isLimitedEdition &&
                            //   !product?.isBoutiqueExclusive &&
                            //   product?.isComingSoon != 2 &&
                            //   !product?.availability?.isOutOfStock &&
                            //   !product?.availability?.preorder &&
                            //   !product?.unlock &&
                            //   !product?.analyticsAttributes?.productTagAnalytics &&
                            //   !product?.analyticsAttributes?.comunicationTagAnalytics
                            //     ? 'none'
                            //     : (product?.isOnlineExclusive ? 'onlineExclusive' : '') +
                            //       (product?.isLimitedEdition ? '-limitedEdition' : '') +
                            //       (product?.isBoutiqueExclusive ? '-boutiqueExclusive' : '') +
                            //       (product?.isComingSoon === 2 ? '-comingSoon' : '') +
                            //       (product?.availability?.isOutOfStock ? '-outOfStock' : '') +
                            //       (product?.availability?.preorder ? '-preorder' : '') +
                            //       (product?.unlock ? '-membersOnly' : '') +
                            //       (product?.analyticsAttributes?.productTagAnalytics ? '-' + product?.analyticsAttributes?.productTagAnalytics.toLowerCase() : '') +
                            //       (product?.analyticsAttributes?.comunicationTagAnalytics ? '-' + product?.analyticsAttributes?.comunicationTagAnalytics.toLowerCase() : ''),
                            dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     *
     * track user that go back from PDP to PLP
     *
     * @param product product listing
     */
    productDetailPageBack: (products, isShelf) => {
        var _a;
        // console.info('_____ productDetailPageBack');
        push({
            event: 'productImpressionsBack',
            ecommerce: {
                currencyCode: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: products.map((product) => {
                    var _a, _b, _c, _d, _e;
                    return (Object.assign(Object.assign({}, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, list: ((_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.list) + (isShelf ? '_shelf' : ''), dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension61, dimension111: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension56, dimension112: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension112 }));
                }),
            },
        });
    },
    /**
     *  PDP - Product Detail Change
     */
    productDetailChange: (product, productSize) => {
        var _a, _b, _c, _d, _e;
        if (!product || (product === null || product === void 0 ? void 0 : product.type) === 'tile' || !(product === null || product === void 0 ? void 0 : product.analyticsAttributes)) {
            // tile is a temporary subset initially loaded during the transition
            return;
        }
        // console.info('_____ productDetailChange');
        push({
            event: 'productDetailChange',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                detail: {
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension14: (productSize === null || productSize === void 0 ? void 0 : productSize.ATS) > 0 ? 'instock' : 'outofstock', dimension36: productSize === null || productSize === void 0 ? void 0 : productSize.id, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension61, dimension111: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension56, dimension112: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     * To track users who interact with the accordion menu
     * @param sectionClicked - clicked accordion title
     * @param pid - product id
     */
    openAccordionInfo: (sectionClicked, pid) => {
        // console.info('_____ openAccordionInfo');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'product information',
            eventLabel: `${sectionClicked}-${pid}`,
        });
    },
    /**
     * To track users who interact with the PDP personalize button
     * @param sectionClicked - clicked accordion title
     * @param pid - product id
     */
    pdpPersonalize: (pid) => {
        // console.info('_____ pdpPersonalize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'make your own',
            eventLabel: pid,
        });
    },
    /**
     * To track users who go to parfum details
     * @param pid - product id
     */
    discoverMoreParfum: (pid) => {
        // console.info('_____ discoverMoreParfum');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'go to editorial page',
            eventLabel: `${pid}`,
        });
    },
    /**
     * Led Packaging Store Badge click
     */
    storeBadgeClick: (eventAction, pid) => {
        // console.info('_____ storeBadgeClick');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: `led packaging-${eventAction}`,
            eventLabel: pid,
        });
    },
    /**
     * NFT - Pre-purchase
     */
    nftBoxLink: () => {
        // console.info('_____ nftBoxLink');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'nft info',
            eventLabel: 'learn more',
        });
    },
    nftClickNft: (nftName) => {
        // console.info('_____ nftClickNft');
        push({
            event: 'nft',
            eventCategory: 'nft',
            eventAction: 'click nft',
            eventLabel: nftName,
        });
    },
    nftProofOwnBtn: (nftName) => {
        // console.info('_____ nftProofOwnBtn');
        push({
            event: 'nft',
            eventCategory: 'nft',
            eventAction: 'proof of ownership',
            eventLabel: nftName,
        });
    },
    /**
     * To track users who wants perfume refill
     * @param label - button label selected
     */
    perfumeWantRefill: (label) => {
        // console.info('_____ perfumeWantRefill');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'refill popup',
            eventLabel: `${label}`,
        });
    },
    addToCartRefill: (product) => {
        var _a, _b;
        // console.info('_____ addToCartRefill');
        push({
            event: 'addToCartRefill',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign(Object.assign({}, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, quantity: 1, dimension36: '150', dimension39: 'parfums' }),
                    ],
                },
            },
        });
    },
    /**
     *  PDP - Add to Bag
     */
    addToBag: (product, productSize, productPrice, giftcardPrice = null) => {
        var _a, _b, _c, _d, _e, _f;
        // console.info('_____ addToBag', product);
        push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign(Object.assign({}, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: giftcardPrice ? Number(giftcardPrice) : (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, quantity: 1, dimension36: giftcardPrice ? '' : productSize.id, metric1: (_c = priceValue(productPrice)) === null || _c === void 0 ? void 0 : _c.value, list: 'pdp', dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension61, dimension111: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension56, dimension112: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     *  PDP - Preorder (Details)
     */
    preorderDetail: (product, productSize, productPrice) => {
        var _a, _b, _c, _d, _e;
        // console.info('_____ preorderDetail');
        push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = priceValue(productPrice)) === null || _b === void 0 ? void 0 : _b.value, quantity: 1 }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension36: productSize.id, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension61, dimension111: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension56, dimension112: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     *  PDP - Hero Images
     */
    heroImages: (productId) => {
        // console.info('_____ heroImages');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'zoom',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - Hotspots
     */
    hostsposts: (eventAction, eventLabel) => {
        // console.info('_____ hostsposts');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: eventAction,
            eventLabel: eventLabel,
        });
    },
    /**
     *  Cart - Edit the Gift Card
     */
    editTheGiftcard: () => {
        push({
            event: 'generic_cart',
            eventCategory: 'gift card',
            eventAction: 'edit',
        });
    },
    /**
     *  PDP - Add to Cart (Gift Card)
     */
    addToCartGiftCard: (productId) => {
        // console.info('_____ addToCartGiftCard');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'shop now',
            eventLabel: productId,
        });
    },
    /**
     *  popup giftcard - Add Giftcard to Bag
     */
    addGiftcardToBag: (info) => {
        // console.info('_____ addToCartGiftCard');
        push({
            event: 'popup giftcard',
            eventCategory: 'popup_addtobag',
            eventAction: info && info.gcAmount,
            eventLabel: info.gcMessage ? 'personalization_true' : 'personalization_false',
        });
    },
    /**
     *  PDP - Check Balance (Gift Card)
     */
    checkGiftcardBalance: (productId) => {
        // console.info('_____ checkGiftcardBalance');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'check balance',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - Details & Care
     */
    detailsAndCare: (cta, MFC) => {
        // console.info('_____ details&Care');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: `${'details & care' + (cta ? '-' + cta.toLowerCase() : '')}`,
            eventLabel: MFC,
        });
    },
    /**
     *  PDP - SizeGuide
     */
    sizeGuide: (productId) => {
        // console.info('_____ sizeGuide');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'size and fit-size guide',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - SizeGuide Overlay
     */
    sizeGuideOverlay: (productId, tab) => {
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: `size guide overlay - ${tab}`,
            eventLabel: productId,
        });
    },
    /** SEE SIMILAR PRODUCT BUTTON PDP */
    seeSimilarProducts: (productId) => {
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'see similar',
            eventLabel: productId,
        });
    },
    /** FLOATING MODULE - SIMILAR PRODUCTS PDP */
    openFloatingModule: () => {
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'popup click',
            eventLabel: 'similar items',
        });
    },
    /** Open Live Chat */
    openLiveChat: (productId) => {
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'moncler live chat',
            eventLabel: productId || '',
        });
    },
    getNotificationModalAction: (type, title, action) => {
        // console.info('_____ getNotificationModalAction');
        push({
            event: type === 'homepage' ? 'click' : type,
            eventCategory: type,
            eventAction: title,
            eventLabel: `get a notification-${action}`,
        });
    },
    /**
     *  PDP/PLP - Notify Me (open modal)
     */
    notifyMe: (productId, source, configurator) => {
        const dataLayer = {
            event: source,
            eventCategory: source,
            eventAction: configurator ? 'configurator notify me' : 'notify me',
            eventLabel: productId,
        };
        push(dataLayer);
        // console.info('_____ notifyMe', dataLayer);
    },
    /**
     *  PDP/PLP - Notify Me Submit
     */
    notifyMeSubmit: (size, productid, source, configurator) => {
        const prefix = size != '' ? size + '-' : '';
        const pid = productid != undefined ? productid : new URLSearchParams(location.search).get('pid');
        const pageTitle = window.datalayerdata.pageCategory;
        const dataLayer = {
            event: source || pageTitle,
            eventCategory: source || pageTitle,
            eventAction: configurator ? 'configurator notify me-submit' : 'notify me-submit',
            eventLabel: prefix + pid,
        };
        push(dataLayer);
        // console.info('_____ notifyMeSubmit', dataLayer);
    },
    /**
     *  PDP - Book an Appointment
     */
    bookAnAppointment: (productId) => {
        // console.info('_____ bookAnAppointment');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'book an appointment',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - Find in Boutique - open
     */
    findInBoutique: (productId) => {
        // console.info('_____ findInBoutique');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'find in boutique',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - Find in Boutique - open size
     */
    findInBoutiqueOpenSize: (productId) => {
        // console.info('_____ findInBoutiqueOpenSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'find in boutique-size',
            eventLabel: `open-${productId}`,
        });
    },
    /**
     *  PDP - Find in Boutique - selected size
     */
    findInBoutiqueSelectedSize: (size, productId) => {
        // console.info('_____ findInBoutiqueSelectedSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'find in boutique-size',
            eventLabel: `${size}-${productId}`,
        });
    },
    /**
     *  PDP - Find in Boutique - submit
     */
    findInBoutiqueSubmit: (productId) => {
        // console.info('_____ findInBoutiqueSubmit');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'find in boutique-submit',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - Reserve in Boutique - open
     */
    reserveInBoutique: (productId) => {
        // console.info('_____ reserveInBoutique');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'reserve in boutique',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - Reserve in Boutique - open size
     */
    reserveInBoutiqueOpenSize: (productId) => {
        // console.info('_____ reserveInBoutiqueOpenSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'reserve in boutique-size',
            eventLabel: `open-${productId}`,
        });
    },
    /**
     *  PDP - Reserve in Boutique - selected size
     */
    reserveInBoutiqueSelectedSize: (size, productId) => {
        // console.info('_____ reserveInBoutiqueSelectedSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'reserve in boutique-size',
            eventLabel: `${size}-${productId}`,
        });
    },
    /**
     *  PDP - Reserve in Boutique - Overlay
     */
    reserveInBoutiqueOverlay: (productId, size) => {
        // console.info('_____ reserveInBoutiqueOverlay');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'reserve in boutique',
            eventLabel: `${productId}${size} - overlay`,
        });
    },
    /**
     *  PDP - Reserve in Boutique - submit
     */
    reserveInBoutiqueSubmit: (productId, size, availability) => {
        // console.info('_____ reserveInBoutiqueSubmit');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'reserve in boutique-submit',
            eventLabel: `${productId}${size}-${availability}`,
        });
    },
    /**
     *  PDP - Preorder
     */
    preorder: (productId) => {
        // console.info('_____ preorder');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'preorder',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - open size selector
     */
    openSize: (productId) => {
        // console.info('_____ openSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'size selection',
            eventLabel: `open-${productId}`,
        });
    },
    /**
     *  PDP - selected size selector
     */
    selectedSize: (size, productId) => {
        // console.info('_____ selectedSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'size selection',
            eventLabel: `${size}-${productId}`,
        });
    },
    /**
     *  PDP - shipping & returns
     */
    shippingAndReturnsLabel: (section, productId) => {
        // console.info('_____ shipping&Returns');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: `${section ? 'shipping & returns - ' + section.toLowerCase() : 'show shipping & returns'}`,
            eventLabel: productId,
        });
    },
    /**
     *  PDP - contact us
     */
    contactUsLabel: (productId) => {
        // console.info('_____ contactUs');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'contact us',
            eventLabel: productId,
        });
    },
    /**
     *  PDP - link AGEC Law (FR)
     */
    agecLinkPDP: (productId) => {
        // console.info('_____ selectedSize');
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'composition and care - product sheets',
            eventLabel: `${productId}`,
        });
    },
    checkoutTypeChose: (checkoutType) => {
        // console.info('_____ checkoutTypeChose');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: checkoutType,
        });
    },
    checkoutLogin: () => {
        // console.info('_____ checkoutLogin');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'delivery',
            eventLabel: 'login to speed up',
        });
    },
    expressCheckoutPaymentSelected: (paymentType) => {
        // console.info('_____ expressCheckoutPaymentSelected');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: `express checkout-${paymentType}`,
        });
    },
    checkoutTaxRefundPopup: (label) => {
        // console.info('_____ checkoutTaxRefundPopup');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'tax refund popup',
            eventLabel: label,
        });
    },
    expressCheckoutLoginRegister: (page) => {
        // console.info('_____ expressCheckoutLoginRegister');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: page,
            eventLabel: `login or register`,
        });
    },
    checkoutLoginTyPage: () => {
        // console.info('_____ expressCheckoutLoginRegister');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'thank-you page',
            eventLabel: `login`,
        });
    },
    expressCheckoutSocialLoginRegister: (page, social) => {
        // console.info('_____ expressCheckoutLoginRegister');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: page,
            eventLabel: social + `-login`,
        });
    },
    /**Edit Order - Order Summary
     */
    editOrder: () => {
        // console.info('_____ editOrder');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'order summary',
            eventLabel: `edit order`,
        });
    },
    /**
     *  OnePage Checkout
     *  Mini Bag Overlay - View Bag (Details)
     *  Mini Bag Overlay - Checkout (Details)
     *  Shopping Bag - Proceed to Purchase
     */
    checkout: (products, step, paymentMethod, shippingMethod) => {
        if (paymentMethod == 'scheme') {
            paymentMethod = 'credit card';
        }
        const dataLayer = {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: checkoutStep(step), option: paymentMethod || shippingMethod },
                    products: products.map((p) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                        return (Object.assign(Object.assign({ name: p === null || p === void 0 ? void 0 : p.originalModelName, id: p === null || p === void 0 ? void 0 : p.masterId, price: ((_b = (_a = p === null || p === void 0 ? void 0 : p.price) === null || _a === void 0 ? void 0 : _a.sales) === null || _b === void 0 ? void 0 : _b.value) > 0 ? (_d = (_c = p === null || p === void 0 ? void 0 : p.price) === null || _c === void 0 ? void 0 : _c.sales) === null || _d === void 0 ? void 0 : _d.value : p === null || p === void 0 ? void 0 : p.singleItemValue, quantity: p === null || p === void 0 ? void 0 : p.quantity, category: (_e = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.category }, p === null || p === void 0 ? void 0 : p.analyticsAttributes), { dimension98: generateDim98((_g = (_f = p === null || p === void 0 ? void 0 : p.mbm) === null || _f === void 0 ? void 0 : _f.bom) === null || _g === void 0 ? void 0 : _g.sleeve_personalization, (_j = (_h = p === null || p === void 0 ? void 0 : p.mbm) === null || _h === void 0 ? void 0 : _h.bom) === null || _j === void 0 ? void 0 : _j.front_personalization, (_l = (_k = p === null || p === void 0 ? void 0 : p.mbm) === null || _k === void 0 ? void 0 : _k.bom) === null || _l === void 0 ? void 0 : _l.back_personalization), dimension102: generateDim102Cart(p), dimension104: (p === null || p === void 0 ? void 0 : p.VTOcode) ? true : false, dimension110: (_m = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _m === void 0 ? void 0 : _m.dimension61, dimension111: (_o = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _o === void 0 ? void 0 : _o.dimension56, dimension112: (_p = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _p === void 0 ? void 0 : _p.dimension112 }));
                    }),
                },
            },
        };
        push(dataLayer);
        // console.info('_____ checkout', dataLayer);
    },
    /**  Shopping Bag - Remove Item
     */
    removeitem: (item) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const dataLayer = {
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: (_b = (_a = item === null || item === void 0 ? void 0 : item.price) === null || _a === void 0 ? void 0 : _a.sales) === null || _b === void 0 ? void 0 : _b.currency,
                remove: {
                    products: [
                        Object.assign(Object.assign({ name: item.originalModelName, id: item.masterId, price: ((_d = (_c = item === null || item === void 0 ? void 0 : item.price) === null || _c === void 0 ? void 0 : _c.sales) === null || _d === void 0 ? void 0 : _d.value) > 0 ? (_f = (_e = item === null || item === void 0 ? void 0 : item.price) === null || _e === void 0 ? void 0 : _e.sales) === null || _f === void 0 ? void 0 : _f.value : (item === null || item === void 0 ? void 0 : item.singleItemValue) || ((_h = (_g = item === null || item === void 0 ? void 0 : item.price) === null || _g === void 0 ? void 0 : _g.sales) === null || _h === void 0 ? void 0 : _h.valueOrNull), category: (_j = item.analyticsAttributes) === null || _j === void 0 ? void 0 : _j.category, variant: (_k = item.analyticsAttributes) === null || _k === void 0 ? void 0 : _k.variant, quantity: item.quantity }, item === null || item === void 0 ? void 0 : item.analyticsAttributes), { dimension102: generateDim102Cart(item), dimension104: (item === null || item === void 0 ? void 0 : item.VTOcode) ? true : false, dimension112: (_l = item === null || item === void 0 ? void 0 : item.analyticsAttributes) === null || _l === void 0 ? void 0 : _l.dimension112 }),
                    ],
                },
            },
        };
        push(dataLayer);
        // console.info('_____ removeFromCart', dataLayer);
    },
    /**  Shopping Bag - Decrease Quantity
     */
    decreaseQuantity: (product) => {
        var _a, _b, _c, _d, _e, _f;
        // console.info('_____ decreaseQuantity');
        push({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                remove: {
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: ((_c = (_b = product === null || product === void 0 ? void 0 : product.price) === null || _b === void 0 ? void 0 : _b.sales) === null || _c === void 0 ? void 0 : _c.value) > 0 ? (_e = (_d = product === null || product === void 0 ? void 0 : product.price) === null || _d === void 0 ? void 0 : _d.sales) === null || _e === void 0 ? void 0 : _e.value : product === null || product === void 0 ? void 0 : product.singleItemValue, quantity: product === null || product === void 0 ? void 0 : product.quantity }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension102: generateDim102Cart(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension112: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension112 }),
                    ],
                },
            },
        });
    },
    /**  Shopping Bag - Increase Quantity
     */
    increaseQuantity: (product) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        // console.info('_____ increaseQuantity');
        push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: ((_c = (_b = product === null || product === void 0 ? void 0 : product.price) === null || _b === void 0 ? void 0 : _b.sales) === null || _c === void 0 ? void 0 : _c.value) > 0 ? (_e = (_d = product === null || product === void 0 ? void 0 : product.price) === null || _d === void 0 ? void 0 : _d.sales) === null || _e === void 0 ? void 0 : _e.value : product === null || product === void 0 ? void 0 : product.singleItemValue, quantity: product === null || product === void 0 ? void 0 : product.quantity }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension102: generateDim102Cart(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension61, dimension111: (_g = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension56, dimension112: (_h = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension112 }),
                    ],
                },
            },
        });
    },
    /**  Wishlist interactions
     */
    wishlistInteraction: (id, section) => {
        // console.info('_____ wishlistInteraction');
        push({
            event: 'wishlist interactions',
            eventCategory: 'checkout',
            eventAction: section == 'RECS' ? 'product carousel' : section === null || section === void 0 ? void 0 : section.toLowerCase(),
            eventLabel: `save for later-${id}`,
        });
    },
    wishlistInteractionBag: (label) => {
        // console.info('_____ wishlistInteractionBag');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: label + '-section',
        });
    },
    wishlistInteractionTopBar: (label) => {
        // console.info('_____ wishlistInteractionTopBar');
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'wishlist',
            eventLabel: 'top bar-' + label,
        });
    },
    wishlistInteractionShare: (label) => {
        // console.info('_____ wishlistInteractionShare');
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'wishlist',
            eventLabel: 'share wishlist' + (label != '' ? '-' + label : ''),
        });
    },
    wishlistInteractionLoginRegister: () => {
        // console.info('_____ wishlistInteractionLoginRegister');
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'wishlist',
            eventLabel: 'login or register',
        });
    },
    wishlistInteractionLoginRegisterPageBtn: () => {
        // console.info('_____ wishlistInteractionLoginRegister');
        push({
            event: 'wishlist',
            eventCategory: 'wishlist',
            eventAction: 'login or register',
        });
    },
    wishlistInteractionComplete: (label) => {
        // console.info('_____ wishlistInteractionComplete');
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'wishlist',
            eventLabel: label + ' complete',
        });
    },
    wishlistInteractionBack: () => {
        // console.info('_____ wishlistInteractionBack');
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'empty wishlist',
            eventLabel: 'back to shopping',
        });
    },
    wishlistInteractionMbmDiscover: (eventLabel) => {
        let dataLayer = {
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            // eventAction: 'banner interaction',
            eventAction: 'empty wishlist',
            eventLabel: eventLabel,
        };
        push(dataLayer);
        // console.info('_____ wishlistInteractionMbmDiscover', dataLayer);
    },
    wishlistInteractionNotify: (id) => {
        // console.info('_____ wishlistInteractionNotify', id);
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'notify me',
            eventLabel: id,
        });
    },
    wishlistInteractionNotifySubmit: (id) => {
        // console.info('_____ wishlistInteractionNotifySubmit', id);
        push({
            event: 'wishlist interactions',
            eventCategory: 'wishlist',
            eventAction: 'notify me-submit',
            eventLabel: id,
        });
    },
    // EDIT Button Wishlist MBM [BONE-170]
    wishlistInteractionMbm: (action) => {
        // console.info('_____ wishlistInteractionNotifySubmit', id);
        push({
            event: 'wishlist interactions',
            eventCategory: 'configurator',
            eventAction: action,
        });
    },
    /**  Save for Later - Cart to Wishlist
     */
    saveForLaterAddToWishlist: (product, size) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
        let analyticsAttributes = {};
        if (product === null || product === void 0 ? void 0 : product.analyticsAttributesGa4) {
            analyticsAttributes = product === null || product === void 0 ? void 0 : product.analyticsAttributesGa4;
        }
        else if (product === null || product === void 0 ? void 0 : product.analyticsAttributes) {
            const productAnalyticsAttributes = product === null || product === void 0 ? void 0 : product.analyticsAttributes;
            analyticsAttributes = {
                currency: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                item_category: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.category,
                item_category2: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.dimension14,
                item_category3: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.dimension39,
                item_category4: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.dimension61,
                item_category5: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.dimension74,
                item_id: product === null || product === void 0 ? void 0 : product.masterId,
                item_name: product === null || product === void 0 ? void 0 : product.originalModelName,
                item_variant: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.variant,
                price: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.price,
                quantity: productAnalyticsAttributes === null || productAnalyticsAttributes === void 0 ? void 0 : productAnalyticsAttributes.quantity,
            };
        }
        const dataLayer = {
            event: 'add_to_wishlist',
            ecommerce: {
                currency: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.currency,
                value: (_c = price(product)) === null || _c === void 0 ? void 0 : _c.value,
                items: [
                    Object.assign(Object.assign({ item_id: product === null || product === void 0 ? void 0 : product.masterId, item_name: product === null || product === void 0 ? void 0 : product.originalModelName, currency: (_d = price(product)) === null || _d === void 0 ? void 0 : _d.currency }, analyticsAttributes), { price: (_f = (_e = product === null || product === void 0 ? void 0 : product.price) === null || _e === void 0 ? void 0 : _e.sales) === null || _f === void 0 ? void 0 : _f.formatted, quantity: 1, size: size || ((_g = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension36) || 'none', item_gender: product === null || product === void 0 ? void 0 : product.analyticsAttributes.dimension38, item_hierarchy_pdp: product === null || product === void 0 ? void 0 : product.analyticsAttributes.dimension52, parent_model: product === null || product === void 0 ? void 0 : product.masterId, item_collection: (product === null || product === void 0 ? void 0 : product.mbm) ? 'moncler by me' : product === null || product === void 0 ? void 0 : product.analyticsAttributes.dimension73, pre_order: ((_k = (_j = (_h = product === null || product === void 0 ? void 0 : product.variationAttributes) === null || _h === void 0 ? void 0 : _h.find((va) => va.values)) === null || _j === void 0 ? void 0 : _j.values.find((value) => value.preorderable)) === null || _k === void 0 ? void 0 : _k.preorderable) || false, item_MFC: (product === null || product === void 0 ? void 0 : product.masterId) + ((_l = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _l === void 0 ? void 0 : _l.variant) ||
                            ((_m = product === null || product === void 0 ? void 0 : product.variationAttributes) === null || _m === void 0 ? void 0 : _m.find((va) => va.values).values.find((value) => value.productId).productId) ||
                            '', item_label: [
                            (((_o = product === null || product === void 0 ? void 0 : product.availability) === null || _o === void 0 ? void 0 : _o.isOutOfStock) &&
                                ((product === null || product === void 0 ? void 0 : product.isComingSoon) !== 2 || (product === null || product === void 0 ? void 0 : product.analyticsAttributes.isComingSoon) === 'false') &&
                                (!(product === null || product === void 0 ? void 0 : product.isBoutiqueExclusive) || ((_p = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _p === void 0 ? void 0 : _p.isBoutiqueExclusive) === 'false') &&
                                'out of stock') ||
                                '',
                            (((product === null || product === void 0 ? void 0 : product.isOnlineExclusive) || ((_q = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _q === void 0 ? void 0 : _q.isOnlineExclusive) == 'true') && 'online exclusive') || '',
                            (((product === null || product === void 0 ? void 0 : product.isLimitedEdition) || ((_r = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _r === void 0 ? void 0 : _r.isLimitedEdition) == 'true') && 'limited edition') || '',
                            (((product === null || product === void 0 ? void 0 : product.isBoutiqueExclusive) || ((_s = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _s === void 0 ? void 0 : _s.isBoutiqueExclusive) == 'true') && 'boutique exclusive') || '',
                            ((product === null || product === void 0 ? void 0 : product.VTOcode) && 'virtual try on') || '',
                            (((_v = (_u = (_t = product === null || product === void 0 ? void 0 : product.variationAttributes) === null || _t === void 0 ? void 0 : _t.find((va) => va.values)) === null || _u === void 0 ? void 0 : _u.values.find((value) => value.preorderable)) === null || _v === void 0 ? void 0 : _v.preorderable) && 'preorder') || '',
                            (((_w = product === null || product === void 0 ? void 0 : product.earlyaccess) === null || _w === void 0 ? void 0 : _w.private) && 'early access') || '',
                            (((product === null || product === void 0 ? void 0 : product.isMonclerCurators) || ((_x = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _x === void 0 ? void 0 : _x.isMonclerCurators) == 'true') && 'moncler curators') || '',
                            ((!(product === null || product === void 0 ? void 0 : product.isBoutiqueExclusive) || ((_y = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _y === void 0 ? void 0 : _y.isBoutiqueExclusive) == 'false') &&
                                ((product === null || product === void 0 ? void 0 : product.isComingSoon) == 2 || (product === null || product === void 0 ? void 0 : product.analyticsAttributes.isComingSoon) == 'true') &&
                                'coming soon') ||
                                '',
                            ((product === null || product === void 0 ? void 0 : product.scarcityProduct) && 'scarcity product') || (product === null || product === void 0 ? void 0 : product.comunicationTagAnalytics) || (product === null || product === void 0 ? void 0 : product.comunicationTag) || '',
                            (product === null || product === void 0 ? void 0 : product.productTagAnalytics) || ((_z = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _z === void 0 ? void 0 : _z.productTagAnalytics) || '',
                        ]
                            .filter((el) => {
                            return /\S/.test(el);
                        })
                            .join('-')
                            .toLowerCase() || 'none', vto: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, configurator_personalizations: generateDim98((_1 = (_0 = product === null || product === void 0 ? void 0 : product.mbm) === null || _0 === void 0 ? void 0 : _0.bom) === null || _1 === void 0 ? void 0 : _1.sleeve_personalization, (_3 = (_2 = product === null || product === void 0 ? void 0 : product.mbm) === null || _2 === void 0 ? void 0 : _2.bom) === null || _3 === void 0 ? void 0 : _3.front_personalization, (_5 = (_4 = product === null || product === void 0 ? void 0 : product.mbm) === null || _4 === void 0 ? void 0 : _4.bom) === null || _5 === void 0 ? void 0 : _5.back_personalization) || 'none', item_line: (_6 = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _6 === void 0 ? void 0 : _6.dimension61, parent_model_color: (_7 = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _7 === void 0 ? void 0 : _7.dimension56 }),
                ],
            },
        };
        push(dataLayer);
        // console.info('_____ saveForLaterAddToWishlist xxx', dataLayer);
    },
    /**  MBM
     */
    mbmAddToWishlist: (event, object) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13;
        const search = new URLSearchParams(location.search);
        const dataLayer = {
            event: 'add_to_wishlist',
            ecommerce: {
                currency: ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.currency) ? (_b = event === null || event === void 0 ? void 0 : event.data) === null || _b === void 0 ? void 0 : _b.currency : (_c = price(object)) === null || _c === void 0 ? void 0 : _c.currency,
                value: ((_d = event === null || event === void 0 ? void 0 : event.data) === null || _d === void 0 ? void 0 : _d.value) ? (_e = event === null || event === void 0 ? void 0 : event.data) === null || _e === void 0 ? void 0 : _e.value : (_f = price(object)) === null || _f === void 0 ? void 0 : _f.value,
                items: [
                    {
                        item_id: object === null || object === void 0 ? void 0 : object.masterId,
                        item_name: object === null || object === void 0 ? void 0 : object.originalModelName,
                        index: 0,
                        item_category: (_g = object === null || object === void 0 ? void 0 : object.analyticsAttributesGa4) === null || _g === void 0 ? void 0 : _g.item_category,
                        item_category2: (_h = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension14,
                        item_category3: (_j = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _j === void 0 ? void 0 : _j.dimension39,
                        item_category4: (_k = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _k === void 0 ? void 0 : _k.dimension61,
                        item_category5: (_l = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _l === void 0 ? void 0 : _l.dimension74,
                        item_variant: (_m = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _m === void 0 ? void 0 : _m.variant,
                        price: ((_o = event === null || event === void 0 ? void 0 : event.data) === null || _o === void 0 ? void 0 : _o.value) ? (_p = event === null || event === void 0 ? void 0 : event.data) === null || _p === void 0 ? void 0 : _p.value : (_q = price(object)) === null || _q === void 0 ? void 0 : _q.value,
                        quantity: ((_r = event === null || event === void 0 ? void 0 : event.data) === null || _r === void 0 ? void 0 : _r.quantity) ? (_s = event === null || event === void 0 ? void 0 : event.data) === null || _s === void 0 ? void 0 : _s.quantity : 1,
                        currency: ((_t = event === null || event === void 0 ? void 0 : event.data) === null || _t === void 0 ? void 0 : _t.currency) ? (_u = event === null || event === void 0 ? void 0 : event.data) === null || _u === void 0 ? void 0 : _u.currency : (_v = price(object)) === null || _v === void 0 ? void 0 : _v.currency,
                        size: (event ? event === null || event === void 0 ? void 0 : event.size : search.get('size')) || '',
                        item_gender: (_w = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _w === void 0 ? void 0 : _w.dimension38,
                        item_hierarchy_pdp: (_x = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _x === void 0 ? void 0 : _x.dimension52,
                        parent_model: object === null || object === void 0 ? void 0 : object.masterId,
                        item_collection: 'moncler by me',
                        pre_order: false,
                        item_MFC: (object === null || object === void 0 ? void 0 : object.masterId) + ((_y = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _y === void 0 ? void 0 : _y.variant),
                        item_label: [
                            ((object === null || object === void 0 ? void 0 : object.isOutOfStock) === 'true' &&
                                ((_z = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _z === void 0 ? void 0 : _z.isComingSoon) !== 2 &&
                                ((_0 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _0 === void 0 ? void 0 : _0.isBoutiqueExclusive) === 'false' &&
                                'out of stock') ||
                                '',
                            (((_1 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _1 === void 0 ? void 0 : _1.isOnlineExclusive) === 'true' && 'online exclusive') || '',
                            (((_2 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _2 === void 0 ? void 0 : _2.isLimitedEdition) === 'true' && 'limited edition') || '',
                            (((_3 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _3 === void 0 ? void 0 : _3.isBoutiqueExclusive) === 'true' && 'boutique exclusive') || '',
                            ((object === null || object === void 0 ? void 0 : object.VTOcode) && (object === null || object === void 0 ? void 0 : object.type) == 'tile' && 'virtual try on') || '',
                            (((_4 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _4 === void 0 ? void 0 : _4.earlyAccessCampaign) && 'early access') || '',
                            ((object === null || object === void 0 ? void 0 : object.isMonclerCurators) === 'true' && 'moncler curators') || '',
                            (((_5 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _5 === void 0 ? void 0 : _5.isBoutiqueExclusive) === 'false' && ((_6 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _6 === void 0 ? void 0 : _6.isComingSoon) === 2 && 'coming soon') || '',
                            ((object === null || object === void 0 ? void 0 : object.scarcityProduct) === 'true' && 'scarcity product') || ((_7 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _7 === void 0 ? void 0 : _7.comunicationTagAnalytics) || '',
                            ((_8 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _8 === void 0 ? void 0 : _8.productTagAnalytics) || ((_9 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _9 === void 0 ? void 0 : _9.productTag) || '',
                        ]
                            .filter((el) => {
                            return /\S/.test(el);
                        })
                            .join('-')
                            .toLowerCase() || 'none',
                        vto: ((_10 = object === null || object === void 0 ? void 0 : object.product) === null || _10 === void 0 ? void 0 : _10.VTOcode) ? true : false,
                        configurator_personalizations: ((_11 = event === null || event === void 0 ? void 0 : event.data) === null || _11 === void 0 ? void 0 : _11.dimension98) || 'none',
                        item_line: (_12 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _12 === void 0 ? void 0 : _12.dimension61,
                        parent_model_color: (_13 = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _13 === void 0 ? void 0 : _13.dimension56, // erpFirstCarryOver
                    },
                ],
            },
        };
        push(dataLayer);
        // console.info('_____ mbmAddToWishlist dataLayer:', dataLayer);
    },
    mbmAddToCart: (event, object) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        const search = new URLSearchParams(location.search);
        object = object !== null && object !== void 0 ? object : JSON.parse(search.get('analyticsProductModel'));
        const dataLayer = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: event ? event === null || event === void 0 ? void 0 : event.formattedCurrency : search.get('formattedCurrency'),
                add: {
                    products: [
                        {
                            variant: (_a = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _a === void 0 ? void 0 : _a.variant,
                            category: (_b = object === null || object === void 0 ? void 0 : object.analyticsAttributesGa4) === null || _b === void 0 ? void 0 : _b.item_category,
                            dimension14: (_c = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension14,
                            dimension22: (_d = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension22,
                            dimension36: event ? event === null || event === void 0 ? void 0 : event.size : search.get('size'),
                            dimension38: (_e = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension38.toLowerCase(),
                            dimension39: (_f = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension39,
                            dimension61: (_g = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension61,
                            dimension52: 'configurator',
                            dimension73: 'MONCLER BY ME',
                            dimension74: (_h = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension74,
                            dimension98: event ? event === null || event === void 0 ? void 0 : event.dimension98 : search.get('dimension98'),
                            dimension56: (_j = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _j === void 0 ? void 0 : _j.dimension56,
                            name: object === null || object === void 0 ? void 0 : object.originalModelName,
                            id: object === null || object === void 0 ? void 0 : object.masterId,
                            price: event ? event === null || event === void 0 ? void 0 : event.price : search.get('price'),
                            quantity: event ? event === null || event === void 0 ? void 0 : event.quantity : 1,
                            dimension102: generateDim102(object === null || object === void 0 ? void 0 : object.product),
                            dimension104: ((_k = object === null || object === void 0 ? void 0 : object.product) === null || _k === void 0 ? void 0 : _k.VTOcode) ? true : false,
                            dimension110: (_l = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _l === void 0 ? void 0 : _l.dimension61,
                            dimension111: (_m = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _m === void 0 ? void 0 : _m.dimension56,
                            dimension112: (_o = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _o === void 0 ? void 0 : _o.dimension112, // permanentPrevPrdCode
                        },
                    ],
                },
            },
        };
        push(dataLayer);
        // console.info('_____ mbmAddToCart', dataLayer);
    },
    mbmAddToCartAtFinish: (pid) => {
        const search = new URLSearchParams(location.search);
        const allowed = [
            'category',
            'dimension14',
            'dimension22',
            'dimension38',
            'dimension39',
            'dimension56',
            'dimension61',
            'dimension74',
            'id',
            'name',
            'price',
            'quantity',
            'variant',
        ];
        api.getProduct(pid).then((data) => {
            var _a, _b, _c;
            const product = data;
            const productAnalyticsAttributes = product === null || product === void 0 ? void 0 : product.analyticsAttributes;
            const dataLayer = {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: search.get('formattedCurrency'),
                    add: {
                        products: [
                            Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: search.get('price'), quantity: 1 }, Object.keys(productAnalyticsAttributes)
                                .filter((key) => allowed.includes(key))
                                .reduce((obj, key) => {
                                obj[key] = productAnalyticsAttributes[key];
                                return obj;
                            }, {})), { dimension36: search.get('size'), dimension52: 'configurator', dimension73: 'MONCLER BY ME', dimension98: search.get('dimension98'), dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_a = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _a === void 0 ? void 0 : _a.dimension61, dimension111: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension56, dimension112: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension112 }),
                        ],
                    },
                },
            };
            push(dataLayer);
            // console.info('_____ mbmAddToCartAtFinish', dataLayer);
        });
    },
    mbmDetail: (event, object) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        const dataLayer = {
            event: 'productDetailViews',
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: object === null || object === void 0 ? void 0 : object.originalModelName,
                            id: object === null || object === void 0 ? void 0 : object.masterId,
                            price: (_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.price,
                            variant: (_b = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.variant,
                            category: (_c = object === null || object === void 0 ? void 0 : object.analyticsAttributesGa4) === null || _c === void 0 ? void 0 : _c.item_category,
                            dimension14: (_d = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension14,
                            dimension22: (_e = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension22,
                            dimension36: (_f = event === null || event === void 0 ? void 0 : event.data) === null || _f === void 0 ? void 0 : _f.size,
                            dimension38: (_g = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension38.toLowerCase(),
                            dimension39: (_h = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension39,
                            dimension61: (_j = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _j === void 0 ? void 0 : _j.dimension61,
                            dimension52: 'configurator',
                            dimension56: (_k = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _k === void 0 ? void 0 : _k.dimension56,
                            dimension73: 'MONCLER BY ME',
                            dimension74: (_l = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _l === void 0 ? void 0 : _l.dimension74,
                            dimension98: (_m = event === null || event === void 0 ? void 0 : event.data) === null || _m === void 0 ? void 0 : _m.dimension98,
                            dimension102: generateDim102(object === null || object === void 0 ? void 0 : object.product),
                            dimension104: ((_o = object === null || object === void 0 ? void 0 : object.product) === null || _o === void 0 ? void 0 : _o.VTOcode) ? true : false,
                            dimension110: (_p = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _p === void 0 ? void 0 : _p.dimension61,
                            dimension111: (_q = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _q === void 0 ? void 0 : _q.dimension56,
                            dimension112: (_r = object === null || object === void 0 ? void 0 : object.analyticsAttributes) === null || _r === void 0 ? void 0 : _r.dimension112, // permanentPrevPrdCode
                        },
                    ],
                },
            },
        };
        push(dataLayer);
        // console.info('_____ mbmDetail dataLayer:', dataLayer);
    },
    vtoPlpGeneric: (label) => {
        // console.info('_____ vtoPlpGeneric', label);
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: 'virtual try on',
            eventLabel: label,
        });
    },
    vtoPdpGeneric: (id) => {
        // console.info('_____ vtoPdpGeneric', id);
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'virtual try on',
            eventLabel: id,
        });
    },
    vtoPdpOnboarding: () => {
        // console.info('_____ vtoPdpOnboarding');
        push({
            event: 'pdp',
            eventCategory: 'virtual try on',
            eventAction: 'onboarding',
            eventLabel: 'start',
        });
    },
    vtoPdpClose: () => {
        // console.info('_____ vtoPdpClose');
        push({
            event: 'pdp',
            eventCategory: 'virtual try on',
            eventAction: 'experience',
            eventLabel: 'close',
        });
    },
    /**  addToCart Enhanced ecommerce
     */
    saveForLaterAddToBag: (product) => {
        var _a, _b;
        // console.info('_____ saveForLaterAddToBag');
        push({
            event: 'addToCartSavedForLater',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, quantity: 1 }, product === null || product === void 0 ? void 0 : product.analyticsAttributes),
                    ],
                },
            },
        });
    },
    /**  Shipping Method
     */
    shippingMethod: (step, shippingMethod) => {
        // console.info('_____ shippingMethod');
        push({
            event: 'checkoutOption',
            ecommerce: {
                checkout_option: {
                    actionField: { step: checkoutStep(step), option: shippingMethod },
                },
            },
        });
    },
    shippingInfo: (shippingMethod, products, priceTotal) => {
        var _a;
        const dataLayer = {
            event: 'add_shipping_info',
            ecommerce: {
                currency: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                value: priceTotal,
                shipping_tier: shippingMethod,
                items: products.map((p) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
                    return (Object.assign(Object.assign({ item_id: p === null || p === void 0 ? void 0 : p.masterId, item_name: p === null || p === void 0 ? void 0 : p.originalModelName, currency: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency }, p === null || p === void 0 ? void 0 : p.analyticsAttributesGa4), { price: ((_c = (_b = p === null || p === void 0 ? void 0 : p.price) === null || _b === void 0 ? void 0 : _b.sales) === null || _c === void 0 ? void 0 : _c.value) > 0 ? (_e = (_d = p === null || p === void 0 ? void 0 : p.price) === null || _d === void 0 ? void 0 : _d.sales) === null || _e === void 0 ? void 0 : _e.value : p === null || p === void 0 ? void 0 : p.singleItemValue, quantity: p === null || p === void 0 ? void 0 : p.quantity, size: (_f = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension36, item_gender: (_g = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension38, item_hierarchy_pdp: (_h = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension52, parent_model: p === null || p === void 0 ? void 0 : p.masterId, item_collection: ((p === null || p === void 0 ? void 0 : p.isMbm) && 'moncler by me') || (p === null || p === void 0 ? void 0 : p.analyticsAttributes.dimension73), pre_order: (p === null || p === void 0 ? void 0 : p.availability.preorder) == true ? true : false, item_MFC: (p === null || p === void 0 ? void 0 : p.masterId) + ((_j = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _j === void 0 ? void 0 : _j.variant) || ((_k = p === null || p === void 0 ? void 0 : p.variationAttributes) === null || _k === void 0 ? void 0 : _k.find((va) => va.value).values.find((value) => value.productId).productId) || '', item_label: [
                            (((_l = p === null || p === void 0 ? void 0 : p.availability) === null || _l === void 0 ? void 0 : _l.isOutOfStock) == true &&
                                ((_m = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _m === void 0 ? void 0 : _m.isComingSoon) === 'false' &&
                                ((_o = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _o === void 0 ? void 0 : _o.isBoutiqueExclusive) == 'false' &&
                                'out of stock') ||
                                '',
                            (((_p = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _p === void 0 ? void 0 : _p.isOnlineExclusive) == 'true' && 'online exclusive') || '',
                            (((_q = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _q === void 0 ? void 0 : _q.isLimitedEdition) == 'true' && 'limited edition') || '',
                            (((_r = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _r === void 0 ? void 0 : _r.isBoutiqueExclusive) == 'true' && 'boutique exclusive') || '',
                            ((p === null || p === void 0 ? void 0 : p.VTOcode) && 'virtual try on') || '',
                            (((_s = p === null || p === void 0 ? void 0 : p.availability) === null || _s === void 0 ? void 0 : _s.preorder) == true && 'preorder') || '',
                            (((_t = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _t === void 0 ? void 0 : _t.earlyAccessCampaign) && 'early access') || '',
                            ((_u = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _u === void 0 ? void 0 : _u.isMonclerCurators) == 'true' ? 'moncler curators' : '',
                            (((_v = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _v === void 0 ? void 0 : _v.isBoutiqueExclusive) == 'false' && ((_w = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _w === void 0 ? void 0 : _w.isComingSoon) == 'true' && 'coming soon') || '',
                            (((_x = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _x === void 0 ? void 0 : _x.scarcityProduct) == true && 'scarcity product') || ((_y = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _y === void 0 ? void 0 : _y.comunicationTagAnalytics) || '',
                            ((_z = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _z === void 0 ? void 0 : _z.productTagAnalytics) || '',
                        ]
                            .filter((el) => {
                            return /\S/.test(el);
                        })
                            .join('-')
                            .toLowerCase() || 'none', vto: (p === null || p === void 0 ? void 0 : p.VTOcode) ? true : false, configurator_personalizations: generateDim98((_1 = (_0 = p === null || p === void 0 ? void 0 : p.mbm) === null || _0 === void 0 ? void 0 : _0.bom) === null || _1 === void 0 ? void 0 : _1.sleeve_personalization, (_3 = (_2 = p === null || p === void 0 ? void 0 : p.mbm) === null || _2 === void 0 ? void 0 : _2.bom) === null || _3 === void 0 ? void 0 : _3.front_personalization, (_5 = (_4 = p === null || p === void 0 ? void 0 : p.mbm) === null || _4 === void 0 ? void 0 : _4.bom) === null || _5 === void 0 ? void 0 : _5.back_personalization) || 'none', item_line: (_6 = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _6 === void 0 ? void 0 : _6.dimension61, parent_model_color: (_7 = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _7 === void 0 ? void 0 : _7.dimension56 }));
                }),
            },
        };
        push(dataLayer);
        // console.info('_____ shippingInfo', dataLayer);
    },
    paymentInfo: (paymentMethod, products, priceTotal, giftcard_usage) => {
        var _a;
        let optionalValues = {};
        if (giftcard_usage) {
            optionalValues = { giftcard_usage };
        }
        const dataLayer = {
            event: 'add_payment_info',
            ecommerce: Object.assign(Object.assign({ currency: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency, value: priceTotal, payment_type: paymentMethod }, optionalValues), { items: products.map((p) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
                    return (Object.assign(Object.assign({ item_id: p === null || p === void 0 ? void 0 : p.masterId, item_name: p === null || p === void 0 ? void 0 : p.originalModelName, currency: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency }, p === null || p === void 0 ? void 0 : p.analyticsAttributesGa4), { price: ((_c = (_b = p === null || p === void 0 ? void 0 : p.price) === null || _b === void 0 ? void 0 : _b.sales) === null || _c === void 0 ? void 0 : _c.value) > 0 ? (_e = (_d = p === null || p === void 0 ? void 0 : p.price) === null || _d === void 0 ? void 0 : _d.sales) === null || _e === void 0 ? void 0 : _e.value : p === null || p === void 0 ? void 0 : p.singleItemValue, quantity: p === null || p === void 0 ? void 0 : p.quantity, size: (_f = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension36, item_gender: (_g = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension38, item_hierarchy_pdp: (_h = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension52, parent_model: p === null || p === void 0 ? void 0 : p.masterId, item_collection: ((p === null || p === void 0 ? void 0 : p.isMbm) && 'moncler by me') || (p === null || p === void 0 ? void 0 : p.analyticsAttributes.dimension73), pre_order: ((_j = p === null || p === void 0 ? void 0 : p.availability) === null || _j === void 0 ? void 0 : _j.preorder) == true ? true : false, item_MFC: (p === null || p === void 0 ? void 0 : p.masterId) + ((_k = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _k === void 0 ? void 0 : _k.variant) || ((_l = p === null || p === void 0 ? void 0 : p.variationAttributes) === null || _l === void 0 ? void 0 : _l.find((va) => va.value).values.find((value) => value.productId).productId) || '', item_label: [
                            (((_m = p === null || p === void 0 ? void 0 : p.availability) === null || _m === void 0 ? void 0 : _m.isOutOfStock) == true &&
                                ((_o = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _o === void 0 ? void 0 : _o.isComingSoon) == 'false' &&
                                ((_p = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _p === void 0 ? void 0 : _p.isBoutiqueExclusive) == 'false' &&
                                'out of stock') ||
                                '',
                            (((_q = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _q === void 0 ? void 0 : _q.isOnlineExclusive) == 'true' && 'online exclusive') || '',
                            (((_r = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _r === void 0 ? void 0 : _r.isLimitedEdition) == 'true' && 'limited edition') || '',
                            (((_s = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _s === void 0 ? void 0 : _s.isBoutiqueExclusive) == 'true' && 'boutique exclusive') || '',
                            ((p === null || p === void 0 ? void 0 : p.VTOcode) && 'virtual try on') || '',
                            (((_t = p === null || p === void 0 ? void 0 : p.availability) === null || _t === void 0 ? void 0 : _t.preorder) == true && 'preorder') || '',
                            (((_u = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _u === void 0 ? void 0 : _u.earlyAccessCampaign) && 'early access') || '',
                            ((_v = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _v === void 0 ? void 0 : _v.isMonclerCurators) == 'true' ? 'moncler curators' : '',
                            (((_w = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _w === void 0 ? void 0 : _w.isBoutiqueExclusive) === 'false' && ((_x = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _x === void 0 ? void 0 : _x.isComingSoon) == 'true' && 'coming soon') || '',
                            (((_y = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _y === void 0 ? void 0 : _y.scarcityProduct) == true && 'scarcity product') || ((_z = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _z === void 0 ? void 0 : _z.comunicationTagAnalytics) || '',
                            ((_0 = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _0 === void 0 ? void 0 : _0.productTagAnalytics) || '',
                        ]
                            .filter((el) => {
                            return /\S/.test(el);
                        })
                            .join('-')
                            .toLowerCase() || 'none', vto: (p === null || p === void 0 ? void 0 : p.VTOcode) ? true : false, configurator_personalizations: generateDim98((_2 = (_1 = p === null || p === void 0 ? void 0 : p.mbm) === null || _1 === void 0 ? void 0 : _1.bom) === null || _2 === void 0 ? void 0 : _2.sleeve_personalization, (_4 = (_3 = p === null || p === void 0 ? void 0 : p.mbm) === null || _3 === void 0 ? void 0 : _3.bom) === null || _4 === void 0 ? void 0 : _4.front_personalization, (_6 = (_5 = p === null || p === void 0 ? void 0 : p.mbm) === null || _5 === void 0 ? void 0 : _5.bom) === null || _6 === void 0 ? void 0 : _6.back_personalization) || 'none', item_line: (_7 = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _7 === void 0 ? void 0 : _7.dimension61, parent_model_color: (_8 = p === null || p === void 0 ? void 0 : p.analyticsAttributes) === null || _8 === void 0 ? void 0 : _8.dimension56 }));
                }) }),
        };
        push(dataLayer);
        // console.info('_____ paymentInfo', dataLayer);
    },
    /**  Shipping&Delivery - Time slot
     */
    timeSlot: (day, slot) => {
        // console.info('_____ timeSlot');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'time slot',
            eventLabel: `${day} - ${slot}`,
        });
    },
    /**  Mini Bag Overlay - View Bag
     */
    miniBagViewBag: () => {
        // console.info('_____ miniBagViewBag');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'mini bag',
            eventLabel: 'view bag',
        });
    },
    /**
     *  Cart - need more time - email my bag
     */
    bagNeedMoreTime: (action) => {
        // console.info('_____ bagNeedMoreTime');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: 'need more time-' + action,
        });
    },
    checkoutSavenewCard: () => {
        // console.info('_____ checkoutSavenewCard');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'payment',
            eventLabel: 'save new card',
        });
    },
    /**  Mini Bag Overlay - Checkout
     */
    miniBagCheckout: () => {
        // console.info('_____ miniBagCheckout');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'mini bag',
            eventLabel: 'checkout',
        });
    },
    /**  Mini Bag Overlay - Close
     */
    miniBagClose: () => {
        // console.info('_____ miniBagClose');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'mini bag',
            eventLabel: 'close',
        });
    },
    /**  Shopping Bag - Back to Shopping
     */
    bagBackShopping: () => {
        // console.info('_____ bagBackShopping');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: 'back to shop',
        });
    },
    /**  Shopping Bag - Save for Later
     */
    bagSaveForLater: (masterid) => {
        // console.info('_____ bagSaveForLater');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: `save for later-${masterid}`,
        });
    },
    /**  Shopping Bag - Promo code
     */
    AppliedPromocode: () => {
        // console.info('_____ AppliedPromocode');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: 'promo code-applied',
        });
    },
    /**  Save for Later - Remove Item
     */
    SaveForLaterRemoveItem: (masterid) => {
        // console.info('_____ SaveForLaterRemoveItem');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'save for later',
            eventLabel: `remove-${masterid}`,
        });
    },
    /**
     * Add item to wishlist
     * @param productMF Product code
     */
    addToWishlist: (productMF) => {
        push({
            event: 'wishlist',
            eventCategory: 'checkout',
            eventAction: 'product carousel',
            eventLabel: 'save for later-' + productMF.toLowerCase(),
        });
    },
    /**
     * Add item to cart from wishlist card
     * @param productMF Product code
     */
    wishlistQuickShop: (productMF) => {
        push({
            event: 'wishlist',
            eventCategory: 'wishlist',
            eventAction: 'quick shop',
            eventLabel: productMF,
        });
    },
    /**  Save for Later - Edit Item
     */
    wishlistEditItem: () => {
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: 'edit configuration',
        });
    },
    /**  Checkout as Member or Guest
     */
    checkoutMemberGuest: (checkoutType) => {
        // console.info('_____ checkoutMemberGuest');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: checkoutType,
        });
    },
    /**  Forgot Password
     */
    checkoutLoginForgotPassword: () => {
        // console.info('_____ checkoutLoginForgotPassword');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'forgot password',
        });
    },
    /**  Forgot Password - Send
     */
    checkoutLoginForgotPasswordAction: (actionType) => {
        // console.info('_____ checkoutLoginForgotPasswordAction');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: `forgot password-${actionType}`,
        });
    },
    /**  Packaging Selection
     */
    packagingSelection: (packagingType, isGift) => {
        // console.info('_____ packagingSelection');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'packaging',
            eventLabel: `${packagingType} ${isGift ? '- gift' : ''}`,
        });
    },
    /**  Flag on Billing option
     */
    flagBillingOption: (flagStatus) => {
        // console.info('_____ flagBillingOption');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'payment',
            eventLabel: `billing-${flagStatus}`,
        });
    },
    /**  Add a new Card
     */
    addNewCard: () => {
        // console.info('_____ addNewCard');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'payment',
            eventLabel: 'add a new card',
        });
    },
    /**  Add a new Address
     */
    addNewAddress: () => {
        // console.info('_____ addNewAddress');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shipping',
            eventLabel: 'add a new address',
        });
    },
    /**  Shipping Type
     */
    shippingType: (type) => {
        // console.info('_____ shippingType');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shipping',
            eventLabel: type,
        });
    },
    /**  Pick up from different location
     */
    pickUpDifferentLocation: () => {
        // console.info('_____ pickUpDifferentLocation');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shipping',
            eventLabel: 'pick up-different location',
        });
    },
    /**  Ship to different location
     */
    shipToDifferentLocation: () => {
        // console.info('_____ shipToDifferentLocation');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shipping',
            eventLabel: 'different location',
        });
    },
    /**  Pick up in boutique - Change location
     */
    pickUpChangeLocation: (type) => {
        // console.info('_____ pickUpChangeLocation');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shipping',
            eventLabel: `pick up-${type}-different location`,
        });
    },
    /**  Ship to different location - Change location
     */
    shipToChangeLocation: (type) => {
        // console.info('_____ shipToChangeLocation');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shipping',
            eventLabel: `${type}-different location`,
        });
    },
    /**  Ship to different location - Change location
     */
    checkoutBanner: (position) => {
        // console.info('_____ checkoutBanner');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'thankyou',
            eventLabel: `banner${position}`,
        });
    },
    /**  Confirmation
     */
    confirmation: () => {
        // console.info('_____ confirmation');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'place order',
        });
    },
    /**  Client Service - Aftercare Step 1
     */
    aftercareOne: (type) => {
        // console.info('_____ aftercareOne');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: `request aftercare-${type}`,
        });
    },
    /**  Client Service - Aftercare Step 2
     */
    aftercareTwo: () => {
        // console.info('_____ aftercareTwo');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: `service request-proceed`,
        });
    },
    /**  Client Service - Aftercare Step 3
     */
    aftercareThree: () => {
        // console.info('_____ aftercareThree');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: `product identification-proceed`,
        });
    },
    /**  Client Service - Aftercare Step 4
     */
    aftercareFour: () => {
        // console.info('_____ aftercareFour');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: `upload images-proceed`,
        });
    },
    /**  Client Service - Aftercare Step 5
     */
    aftercareFive: () => {
        // console.info('_____ aftercareFive');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: `review request-send`,
        });
    },
    /**  My Moncler - Mobile only
     */
    LoginRegisterTab: (tab) => {
        // console.info('_____ LoginRegisterTab');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'switch',
            eventLabel: tab,
        });
    },
    /**  Client Service - Tracking
     */
    tracking: (type) => {
        // console.info('_____ tracking');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'tracking',
            eventLabel: type,
        });
    },
    /**  Client Service - Aftercare New Request
     */
    aftercareNewRequest: () => {
        // console.info('_____ aftercareNewRequest');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: 'new aftercare request',
        });
    },
    /**  Client Service - Returns Step 1
     */
    returnsStepOne: (type) => {
        // console.info('_____ returnsStepOne');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'returns',
            eventLabel: type,
        });
    },
    /**  Client Service - Returns Step 1 pt. 2
     */
    returnsStepOneBoutique: (type) => {
        // console.info('_____ returnsStepOneBoutique');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'returns',
            eventLabel: `boutique-${type}`,
        });
    },
    /**  Client Service - Returns Step 2
     */
    returnsStepTwo: () => {
        // console.info('_____ returnsStepTwo');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'returns',
            eventLabel: 'your item-proceed',
        });
    },
    /**  Client Service - Returns Step 3
     */
    returnsStepThree: () => {
        // console.info('_____ returnsStepThree');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'returns',
            eventLabel: 'select reason-proceed',
        });
    },
    /**  Client Service - Returns Step 4
     */
    returnsStepFour: () => {
        // console.info('_____ returnsStepFour');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'returns',
            eventLabel: 'review request-send',
        });
    },
    /**  Client Service - Returns Not Found
     */
    returnsNotFound: () => {
        // console.info('_____ returnsNotFound');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'returns',
            eventLabel: 'not found',
        });
    },
    /**  Client Service - Aftercare Back & Edit
     */
    aftercareBackEdit: (stepName, type) => {
        // console.info('_____ aftercareBackEdit');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'aftercare',
            eventLabel: `${stepName}-${type}`,
        });
    },
    /**  Client Service - Exchange Step 1
     */
    exchangeStepOne: (type) => {
        // console.info('_____ exchangeStepOne');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'exchanges',
            eventLabel: type,
        });
    },
    /**
     * Track users who want to exchange an item in boutique
     * @param ctaClicked cta clicked
     */
    exchangeStepOneBoutique: (ctaClicked) => {
        // console.info('_____ exchangeStepOneBoutique');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'exchanges',
            eventLabel: `boutique-${ctaClicked}`,
        });
    },
    /**  Client Service - Exchange Step 2
     */
    exchangeStepTwo: () => {
        // console.info('_____ exchangeStepTwo');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'exchanges',
            eventLabel: 'your item-proceed',
        });
    },
    /**  Client Service - Contact us 1/2
     */
    contactUsOne: (type) => {
        // console.info('_____ contactUsOne');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'contact us',
            eventLabel: type,
        });
    },
    /**  Client Service - Contact us 2/2
     */
    contactUsTwo: () => {
        // console.info('_____ contactUsTwo');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'contact us',
            eventLabel: 'form submit',
        });
    },
    /**  Client Service - Search
     */
    /**
     * Track user that start client service search
     */
    startCSSearch: () => {
        // console.info('_____ startCSSearch');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'search',
            eventLabel: 'search start',
        });
    },
    /**
     * Track user that start client service search
     * @param searchedQuery - query searched by user
     */
    selectedElement: (searchedQuery) => {
        // console.info('_____ selectedElement');
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'search',
            eventLabel: searchedQuery,
        });
    },
    filterByGender: (gender) => {
        // console.info('_____ filterByGender');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: gender,
        });
    },
    /**  Login
     */
    login: () => {
        // console.info('_____ login');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'login',
            eventLabel: 'login',
        });
    },
    /** Login or Register
     */
    loginOrRegister: (event) => {
        if (event && event == 'menu') {
            var eventCategory = 'account menu';
        }
        // console.info('_____ login');
        push({
            event: event || 'my moncler',
            eventCategory: eventCategory || 'my moncler',
            eventAction: 'login or register',
            eventLabel: 'login or register - start',
        });
    },
    /**  Login MBM
     */
    loginMbm: () => {
        // console.info('_____ loginMbm');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'member checkout',
            eventLabel: 'login',
        });
    },
    popupLogin: () => {
        // console.info('_____ popupLogin');
        push({
            event: 'popup',
            eventCategory: 'popup',
            eventAction: 'login or register',
            eventLabel: 'login or register-start',
        });
    },
    /**  Register MBM
     */
    registerMbm: () => {
        // console.info('_____ registerMbm');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'member checkout',
            eventLabel: 'create account',
        });
    },
    /**  Login Shopping Bag
     */
    loginShoppingBag: () => {
        // console.info('_____ loginShoppingBag');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: 'login',
        });
    },
    loginCheckout: () => {
        // console.info('_____ loginCheckout');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'member checkout',
            eventLabel: 'login',
        });
    },
    backCheckout: () => {
        // console.info('_____ backCheckout');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'member checkout',
            eventLabel: 'back',
        });
    },
    checkoutEditMbm: () => {
        // console.info('_____ checkoutEditMbm');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: 'edit configuration',
        });
    },
    /**  Forgot Password 1/2
     */
    forgotPasswordOne: () => {
        // console.info('_____ forgotPasswordOne');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'forgot password',
        });
    },
    /**  Forgot Password 2/2
     */
    forgotPasswordTwo: (type) => {
        // console.info('_____ forgotPasswordTwo');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'forgot password',
            eventLabel: type,
        });
    },
    shoppingBagLoginRegister: (action) => {
        // console.info('_____ shoppingBagLoginRegister');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'shopping bag',
            eventLabel: action,
        });
    },
    /**  Create Account 1/3
     */
    createAccountOne: () => {
        // console.info('_____ createAccountOne');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'create account',
            eventLabel: 'open',
        });
    },
    /**  Create Account 1/3 Shopping Bag
     */
    createAccountShoppingBagOne: () => {
        // console.info('_____ createAccountShoppingBagOne');
        push({
            event: 'checkout generic',
            eventCategory: 'checkout',
            eventAction: 'member checkout',
            eventLabel: 'register start',
        });
    },
    /**  Create Account 2/3
     */
    createAccountTwo: () => {
        // console.info('_____ createAccountTwo');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'create account',
            eventLabel: 'create account',
        });
    },
    /**  Create Account - Newsletter
     */
    createAccountNl: (preferences) => {
        // console.info('_____ createAccountNl');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'create account',
            eventLabel: `newsletter-${preferences}`,
        });
    },
    /**  My moncler - Privacy settings
     */
    privacySettings: (marketing, profiling, nlcollections) => {
        // console.info('_____ privacySettings');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: marketing || profiling ? `${marketing ? 'marketing' : ''}${marketing && profiling ? '-' : ''}${profiling ? 'profiling' : ''}` : 'no personal consent',
            eventLabel: nlcollections ? nlcollections : 'no interest',
        });
    },
    /**  Create Account 3/3
     */
    createAccountThree: (social) => {
        // console.info('_____ createAccountThree');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: `${social === null || social === void 0 ? void 0 : social.toLowerCase()}-create account`,
            eventLabel: 'create account',
        });
    },
    /**  Social login
     */
    socialLogin: (social) => {
        // console.info('_____ socialLogin');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: `${social}-login`,
            eventLabel: 'login',
        });
    },
    socialLoginGeneric: ({ event = 'menu', eventCategory = 'account menu', eventAction = 'google-login', eventLabel = 'open' }) => {
        push({
            event,
            eventCategory,
            eventAction,
            eventLabel,
        });
    },
    /**  My Moncler - Saved for later 1/3
     */
    savedForLaterOne: (productId) => {
        // console.info('_____ savedForLaterOne');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'saved for later-notify me',
            eventLabel: productId,
        });
    },
    /**  My Moncler - Saved for later 2/3
     */
    savedForLaterTwo: (low, out, back) => {
        // console.info('_____ savedForLaterTwo');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'saved for later-notify me',
            eventLabel: generateLabel(low, out, back),
        });
    },
    /**  My Moncler - Saved for later 3/3
     */
    savedForLaterThree: () => {
        // console.info('_____ savedForLaterThree');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'saved for later',
            eventLabel: 'back to shopping',
        });
    },
    /**  My Moncler - Orders 1/2
     */
    ordersOne: (type) => {
        // console.info('_____ ordersOne');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'orders',
            eventLabel: type,
        });
    },
    /**  My Moncler - Orders 2/2
     */
    ordersTwo: () => {
        // console.info('_____ ordersTwo');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'orders',
            eventLabel: 'back to shopping',
        });
    },
    /**  My Moncler - Returns 2/2
     */
    returnsTwo: () => {
        // console.info('_____ returnsTwo');
        push({
            event: 'my moncler',
            eventCategory: 'my moncler',
            eventAction: 'returns',
            eventLabel: 'back to shopping',
        });
    },
    downloadInvoice: (type, area) => {
        // console.info('_____ downloadInvoice');
        push({
            event: area,
            eventCategory: area,
            eventAction: area == 'my moncler' ? type : 'tracking',
            eventLabel: area == 'my moncler' ? 'download invoice' : type + '-download invoice',
        });
    },
    /**
     * Client Service - Tracking Returns - Generic Events
     */
    downloadCreditnote: (type, area) => {
        // console.info('_____ downloadCreditnote');
        push({
            event: area,
            eventCategory: area,
            eventAction: area == 'my moncler' ? 'tracking' : type,
            eventLabel: area == 'my moncler' ? 'download creditnote' : type + '-download creditnote',
        });
    },
    /**
     * Client Service Redesign [OE-13108]
     */
    clientServiceRedesignAnalytics: (action, element) => {
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: action,
            eventLabel: element,
        });
    },
    /**
     *  promo50/50 - Agec Law [OE-16578]
     */
    promo5050AgecLaw: (element) => {
        push({
            event: 'client service',
            eventCategory: 'client service',
            eventAction: 'agec_law-standalone',
            eventLabel: element,
        });
    },
    /**
     * selection size in PLP
     */
    sizeSelectionPlp: (product, size) => {
        var _a;
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: 'size selection',
            eventLabel: `${size ? size : (_a = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _a === void 0 ? void 0 : _a.dimension36}-${product === null || product === void 0 ? void 0 : product.masterId}`,
        });
    },
    /**
     * Promo Editorial Cards
     */
    promoEditorialCardsAnalytics: (action, label) => {
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: action,
            eventLabel: label,
        });
    },
    /**
     * Slim banner PLP
     */
    slimBannerAnalytics: (title, cta) => {
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: 'click banner product',
            eventLabel: `${title === null || title === void 0 ? void 0 : title.toLowerCase()}-${cta === null || cta === void 0 ? void 0 : cta.toLowerCase()}`,
        });
    },
    /**  Quick Shop - Desktop only
     */
    quickShop: (productId) => {
        // console.info('_____ quickShop');
        const pageType = window.datalayerdata.pageCategory;
        push({
            event: pageType,
            eventCategory: pageType,
            eventAction: 'quick shop',
            eventLabel: productId,
        });
    },
    /**  PLP Personalize CTA [OE-15592]
     */
    plpPersonalizeCta: (productId) => {
        // console.info('_____ quickShop');
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: 'personalize',
            eventLabel: productId,
        });
    },
    /**  PLP Refine - open
     */
    refineOpen: () => {
        // console.info('_____ refineOpen');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'open',
        });
    },
    /**  Search Refine - open
     */
    searchRefineOpen: () => {
        // console.info('_____ searchRefineOpen');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'open',
        });
    },
    /**  PLP Sort by - open
     */
    searchSortOpen: () => {
        // console.info('_____ searchSortOpen');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'sort by',
        });
    },
    /**  PLP Sort by - open
     */
    sortOpen: () => {
        // console.info('_____ sortOpen');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'sort by',
        });
    },
    /**  Search Sort by
     */
    searchSortBy: (sort) => {
        // console.info('_____ searchSortBy');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'sort by',
            eventLabel: sort,
        });
    },
    /**  PLP Sort by
     */
    sortBy: (sort) => {
        // console.info('_____ sortBy');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'sort by',
            eventLabel: sort,
        });
    },
    /**  PLP Refine - reset all
     */
    searchRefineResetAll: () => {
        // console.info('_____ searchRefineResetAll');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'reset all',
        });
    },
    /**  PLP Refine - reset all
     */
    refineResetAll: () => {
        // console.info('_____ refineResetAll');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'reset all',
        });
    },
    /**  PLP Refine - reset all
     */
    refineClearAll: () => {
        // console.info('_____ refineClearAll');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'clear all',
        });
    },
    /**  Search Refine - reset all
     */
    searchRefineClearAll: () => {
        // console.info('_____ searchRefineClearAll');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'clear all',
        });
    },
    countryselectorClose: () => {
        // console.info('_____ countryselectorClose');
        push({
            event: 'country selector',
            eventCategory: 'country selector',
            eventAction: 'close',
        });
    },
    countryselectorClick: (region, country) => {
        // console.info('_____ countryselectorClick');
        push({
            event: 'country selector',
            eventCategory: 'country selector',
            eventAction: region,
            eventLabel: country,
        });
    },
    languageselectorClose: () => {
        // console.info('_____ languageselectorClose');
        push({
            event: 'language selector',
            eventCategory: 'language selector',
            eventAction: 'close',
        });
    },
    languageselectorClick: (language) => {
        // console.info('_____ languageselectorClick');
        push({
            event: 'language selector',
            eventCategory: 'language selector',
            eventAction: language,
        });
    },
    /**  PLP Refine - reset
     */
    refineShow: () => {
        // console.info('_____ refineShow');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'show results',
        });
    },
    /**  Search Refine - reset
     */
    searchRefineShow: () => {
        // console.info('_____ searchRefineShow');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'show results',
        });
    },
    /**  PLP Refine - clear
     */
    refineClear: () => {
        // console.info('_____ refineClear');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: 'clear',
        });
    },
    /**  Search Refine - clear
     */
    searchRefineClear: () => {
        // console.info('_____ searchRefineClear');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: 'clear',
        });
    },
    /**  PLP Refine - first level
     */
    refineFirstLevel: (firstLevel) => {
        // console.info('_____ refineFirstLevel');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: firstLevel,
        });
    },
    /**  PLP Refine - first level
     */
    searchRefineFirstLevel: (firstLevel) => {
        // console.info('_____ searchRefineFirstLevel');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: firstLevel,
        });
    },
    /**  PLP Visual Filters
     */
    visualFiltersAnalytics: (cta) => {
        // console.info('_____ visualFilters');
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: 'click category',
            eventLabel: cta === null || cta === void 0 ? void 0 : cta.toLowerCase(),
        });
    },
    /**  PLP Refine - second level
     */
    refineSecondLevel: (firstLevel, secondLevel) => {
        // console.info('_____ refineSecondLevel');
        push({
            event: 'plp',
            eventCategory: 'refine',
            eventAction: firstLevel,
            eventLabel: secondLevel,
        });
    },
    refineMacrocategory: (search) => {
        var _a;
        const categories = (_a = search === null || search === void 0 ? void 0 : search.replace(/%3A(.*?)%7C/g, '%7C').replace(/%3A(.*)/g, '').split('%7C').slice(1).join('-')) === null || _a === void 0 ? void 0 : _a.toLowerCase().replace('genderfilter', 'gender').replace('microtaxonomy', 'category').replace('productlinecode', 'productline').replace('refinementcolor', 'colour').replace('available', 'availability');
        // console.info('_____ refineMacrocategory', categories);
        push({
            event: 'plp',
            eventCategory: 'refine macrocategory',
            eventAction: categories,
        });
    },
    /**  Search Refine - second level
     */
    searchRefineSecondLevel: (firstLevel, secondLevel) => {
        // console.info('_____ searchRefineSecondLevel');
        push({
            event: 'search',
            eventCategory: 'refine_search',
            eventAction: firstLevel,
            eventLabel: secondLevel,
        });
    },
    searchRefineMacrocategory: (search) => {
        var _a;
        const categories = (_a = search === null || search === void 0 ? void 0 : search.replace(/%3A(.*?)%7C/g, '%7C').replace(/%3A(.*)/g, '').split('%7C').slice(1).join('-')) === null || _a === void 0 ? void 0 : _a.toLowerCase().replace('genderfilter', 'gender').replace('microtaxonomy', 'category').replace('productlinecode', 'productline').replace('refinementcolor', 'colour').replace('available', 'availability');
        // console.info('_____ searchRefineMacrocategory', categories);
        push({
            event: 'search',
            eventCategory: 'refine macrocategory search',
            eventAction: categories,
        });
    },
    /**  PLP Promo image  */
    promoImage: (starting, landing) => {
        // console.info('_____ promoImage');
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: starting,
            eventLabel: landing,
        });
    },
    /**  PLP Shelf - View all
     */
    shelfViewAll: (name) => {
        // console.info('_____ shelfViewAll');
        push({
            event: 'plp',
            eventCategory: 'plp',
            eventAction: name,
            eventLabel: 'view all',
        });
    },
    /**  Quick Shop - Add to bag - Desktop only
     */
    quickShopAddToBag: (masterid, type) => {
        if (type === 'PLP') {
            // console.info('_____ quickShopAddToBag plp');
            push({
                event: 'plp',
                eventCategory: 'plp',
                eventAction: 'add to cart',
                eventLabel: masterid,
            });
        }
        else if (type === 'LOOKPRODUCT') {
            // console.info('_____ quickShopAddToBag pdp');
            push({
                event: 'pdp',
                eventCategory: 'shop the look',
                eventAction: 'add to cart',
                eventLabel: masterid,
            });
        }
    },
    /**  Quick Shop - Add to bag
     */
    quickShopAddToBagDetails: (product, size, from) => {
        var _a, _b, _c, _d, _e, _f;
        const pageTitle = window.datalayerdata.pageCategory;
        const dataLayer = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ name: (product === null || product === void 0 ? void 0 : product.originalModelName) || (product === null || product === void 0 ? void 0 : product.productName), id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, quantity: 1 }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension36: size ? size : (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension36 }), ((from === null || from === void 0 ? void 0 : from.includes('curators')) && { dimension52: 'moncler curators' })), ((from === null || from === void 0 ? void 0 : from.includes('curators')) && { dimension73: 'moncler curators' })), { list: from ? from : pageTitle, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension61, dimension111: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension56, dimension112: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension112 }),
                    ],
                },
            },
        };
        push(dataLayer);
        // console.info('_____ quickShopAddToBagDetails', dataLayer);
    },
    /**
     * Recommended - Tile
     */
    recommendedTile: (product, type, position) => {
        var _a, _b, _c, _d;
        // console.info('_____ recommendedTile');
        push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: type === null || type === void 0 ? void 0 : type.toLowerCase() },
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: position, quantity: 1 }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: undefined, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }),
                    ],
                },
            },
        });
    },
    shopTheLookPdp: (product, position, list) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const dataLayer = {
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: list ? list : `shop_the_look_pdp` },
                    products: [
                        Object.assign(Object.assign({ name: (product === null || product === void 0 ? void 0 : product.originalModelName) ? product === null || product === void 0 ? void 0 : product.originalModelName : product === null || product === void 0 ? void 0 : product.productName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, quantity: 1 }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { 
                            // list: undefined, // https://jira.industries-group.com/browse/ONE-18916
                            list: list ? list : undefined, position: position, 
                            // dimension52: list ? 'moncler curators' : product?.analyticsAttributes?.dimension52,
                            // dimension73: list ? 'moncler curators' : '',
                            dimension52: ((_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension52) ? (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension52 : 'moncler curators', dimension73: ((_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension73) ? (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension73 : 'moncler curators', dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension61, dimension111: (_g = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _g === void 0 ? void 0 : _g.dimension56, dimension112: (_h = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _h === void 0 ? void 0 : _h.dimension112 }),
                    ],
                },
            },
        };
        push(dataLayer);
        // console.info('_____ shopTheLookPdp ', dataLayer);
    },
    /**
     * PLP - productImpressions
     */
    productImpressions: (products, progress, isScroll = false, isShelf = false, category = null) => {
        var _a;
        const impressions = {
            event: `productImpressions${isScroll ? 'Scroll' : ''}`,
            ecommerce: {
                currencyCode: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: products.map((product, index) => {
                    var _a, _b, _c, _d, _e;
                    return (Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: progress + index }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: ((_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.list) + (isShelf ? '_shelf' : ''), dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension61, dimension111: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension56, dimension112: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension112 }));
                }),
            },
        };
        push(impressions);
        // console.info('_____ productImpressions', impressions);
    },
    /**
     * PLP Curator - productImpressions
     */
    productImpressionsCuratorPlp: (products, actionList, isScroll = false) => {
        // console.info('_____ productImpressionsCuratorPlp');
        push({
            event: `productImpressions${isScroll ? 'Scroll' : ''}`,
            ecommerce: {
                impressions: products.map((product, index) => {
                    var _a, _b, _c, _d;
                    return (Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: actionList, dimension52: 'moncler curators', dimension73: 'moncler curators', dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }));
                }),
            },
        });
    },
    /**
     *  Curator - Newsletter Signup
     */
    curatorsNewsletterSignup: () => {
        // console.info('_____ curatorsNewsletterSignup');
        push({
            event: 'flp',
            eventName: 'flp',
            eventCategory: 'moncler curators hub',
            eventAction: 'newsletter sign up',
            eventLabel: 'continue',
        });
    },
    /**
     *  PLP - login to purchase
     */
    loginToPurchase: (productId, page, category) => {
        // console.info('_____ curatorsNewsletterSignup');
        push({
            event: page,
            eventCategory: category,
            eventAction: 'login to purchase',
            eventLabel: productId,
        });
    },
    /**
     *  Curator - Shop the look Action
     */
    curatorsShopTheLook: (eventAction) => {
        // console.info('_____ curatorsShopTheLook');
        push({
            event: 'curators interactions',
            eventCategory: 'moncler curators',
            eventAction: `shop the look ${eventAction + 1}`,
            eventLabel: 'shop the look',
        });
    },
    curatorsLoginOrRegister: () => {
        // console.info('_____ curatorsLoginOrRegister');
        push({
            event: 'curators interactions',
            eventCategory: 'moncler curators',
            eventAction: 'login or register',
            eventLabel: 'open',
        });
    },
    /**
     * PLP - Product Click
     */
    productClick: (product, position, isShelf = false) => {
        var _a, _b, _c, _d, _e, _f;
        // console.info('_____ productClick', product);
        push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: ((_a = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _a === void 0 ? void 0 : _a.list) + (isShelf ? '_shelf' : '') },
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, position: position }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: ((_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.list) + (isShelf ? '_shelf' : ''), dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension61, dimension111: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension56, dimension112: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     * Search - Product Click
     */
    productSearchClick: (product, position) => {
        var _a, _b, _c, _d;
        // console.info('_____ productSearchClick');
        push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: 'search_results' },
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: position }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: undefined, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     * PLP - productImpressionsChange
     */
    productImpressionsChange: (product, position, color, size, from) => {
        var _a, _b, _c, _d, _e, _f;
        const dataLayer = {
            event: 'productImpressionsChange',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: [
                    Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, position: position }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { variant: color ? color : (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.variant, dimension36: size ? size : (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension36 }), ((from === null || from === void 0 ? void 0 : from.includes('curators')) && { dimension52: 'moncler curators' })), ((from === null || from === void 0 ? void 0 : from.includes('curators')) && { dimension73: 'moncler curators' })), { list: from ? from : (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.list, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension112: (_f = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _f === void 0 ? void 0 : _f.dimension112 }),
                ],
            },
        };
        push(dataLayer);
        // console.info('_____ productImpressionsChange', dataLayer);
    },
    productsImpressionsChange: (products, from) => {
        var _a;
        const dataLayer = {
            event: 'productImpressionsChange',
            ecommerce: {
                currencyCode: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: products.map((product, i) => {
                    var _a, _b, _c, _d, _e;
                    return (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: i }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { variant: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.variant, dimension36: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension36 }), ((from === null || from === void 0 ? void 0 : from.includes('curators')) && { dimension52: 'moncler curators' })), ((from === null || from === void 0 ? void 0 : from.includes('curators')) && { dimension73: 'moncler curators' })), { list: from ? from : (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.list, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension112: (_e = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _e === void 0 ? void 0 : _e.dimension112 }));
                }),
            },
        };
        push(dataLayer);
        // console.info('_____ productsImpressionsChange', dataLayer);
    },
    /**
     * Recommended - productImpressions
     */
    productImpressionsRecommended: (products, from) => {
        var _a;
        const dataLayer = {
            event: 'productImpressions',
            ecommerce: {
                currencyCode: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: products.map((product, i) => {
                    var _a, _b, _c, _d;
                    return (Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: i }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: from, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }));
                }),
            },
        };
        push(dataLayer);
        // console.info('_____ productImpressionsRecommended', dataLayer);
    },
    /**
     * Shop The Look - productImpressions
     */
    productImpressionsShopTheLook: (products, list) => {
        var _a;
        // console.info('_____ productImpressionsShopTheLook');
        push({
            event: 'productImpressions',
            ecommerce: {
                currencyCode: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: products.map((product, i) => {
                    var _a, _b, _c, _d;
                    return (Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: i }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension52: 'moncler curators', dimension73: 'moncler curators', list: list, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }));
                }),
            },
        });
    },
    /**
     * Newsletter
     */
    /**
     * Track user clicks on footer items
     *
     * @param firstSectionClicked - accordion title text
     * @param secondSectionClicked - button text
     */
    footerSection: (firstSectionClicked, secondSectionClicked) => {
        // console.info('_____ footerSection');
        push({
            event: 'footer',
            eventCategory: 'footer',
            eventAction: firstSectionClicked === null || firstSectionClicked === void 0 ? void 0 : firstSectionClicked.toLowerCase(),
            eventLabel: secondSectionClicked === null || secondSectionClicked === void 0 ? void 0 : secondSectionClicked.toLowerCase(),
        });
    },
    footerOpen: (firstSectionClicked) => {
        // console.info('_____ footerOpen');
        push({
            event: 'footer',
            eventCategory: 'footer',
            eventAction: firstSectionClicked === null || firstSectionClicked === void 0 ? void 0 : firstSectionClicked.toLowerCase(),
            eventLabel: 'open',
        });
    },
    /**
     * Track user that have completed the newsletter flow
     *
     * @param buttonText - accordion title text
     * @param sectionFlagged - button text
     */
    newsletterSubscription: (buttonText, sectionFlagged, isPopup) => {
        // console.info('_____ newsletterSubscription');
        push({
            event: 'newsletter',
            eventCategory: isPopup ? 'pop_up-newsletter subscription' : 'newsletter subscription',
            eventAction: buttonText === null || buttonText === void 0 ? void 0 : buttonText.toLowerCase(),
            eventLabel: sectionFlagged.join('-'),
        });
    },
    newsletterSubscriptionPopup: () => {
        // console.info('_____ newsletterSubscriptionPopup');
        push({
            event: 'newsletter',
            eventCategory: 'pop_up',
            eventAction: 'newsletter subscription',
            eventLabel: 'continue',
        });
    },
    newsletterCuratorsSubmit: () => {
        // console.info('_____ newsletterCuratorsSubmit');
        push({
            event: 'newsletter',
            eventCategory: 'newsletter subscription',
            eventAction: 'submit',
            eventLabel: 'moncler curators hub',
        });
    },
    /**
     * Track user that close newsletter modal
     */
    newsletterCloseModal: () => {
        // console.info('_____ newsletterCloseModal');
        push({
            event: 'newsletter',
            eventCategory: 'newsletter subscription',
            eventAction: 'close',
        });
    },
    /**
     * MonclerNow
     */
    /**
     * Track user clicks on article with landscape layout
     * @param articleTitle - article title
     */
    landscapeImageClick: (articleTitle) => {
        // console.info('_____ landscapeImageClick');
        push({
            event: 'moncler now',
            eventCategory: 'moncler now',
            eventAction: 'landscape',
            eventLabel: articleTitle,
        });
    },
    /**
     * Track user clicks on article with thumbnail layout
     * @param articleTitle - article title
     */
    thumbnailClick: (articleTitle) => {
        // console.info('_____ thumbnailClick');
        push({
            event: 'moncler now',
            eventCategory: 'moncler now',
            eventAction: 'thumbnail articles',
            eventLabel: articleTitle,
        });
    },
    /**
     * Track user clicks on "continue" button in newsletter module
     */
    newsletterModuleContinue: () => {
        // console.info('_____ newsletterModuleContinue');
        push({
            event: 'moncler now',
            eventCategory: 'moncler now',
            eventAction: 'newsletter signup',
            eventLabel: 'continue',
        });
    },
    /**
     * Track user clicks on "load more stories" button
     */
    loadMoreStories: () => {
        // console.info('_____ loadMoreStories');
        push({
            event: 'moncler now',
            eventCategory: 'moncler now',
            eventAction: 'more stories',
        });
    },
    /**
     * Track user interacts with filter bar
     * @param filterApplied - name of filter applied
     */
    filterArticles: (filterApplied) => {
        // console.info('_____ filterArticles');
        push({
            event: 'moncler now',
            eventCategory: 'moncler now',
            eventAction: 'filter menu',
            eventLabel: filterApplied,
        });
    },
    /**
     * Track user who want to share the article by social sharing snippet
     * @param socialNetwork - name of social choosen
     */
    shareArticle: (socialNetwork) => {
        // console.info('_____ shareArticle');
        push({
            event: 'social',
            socialNetwork,
            socialAction: 'share',
            socialTarget: location.href,
        });
    },
    /**
     * FLP Editorial carousel
     */
    /**
     * Track user clicks on left/right arrow of the flp editorial carousel
     * @param title - title of the module
     */
    editorialCarouselArrowClick: (title) => {
        // console.info('_____ editorialCarouselArrowClick');
        push({
            event: 'flp',
            eventCategory: 'flp',
            eventAction: 'editorial carousel',
            eventLabel: `${title}-arrow`,
        });
    },
    /**
     * FLP Designers slider
     */
    /**
     * Track user starts using drag-to-explore
     * button in the genius slider
     */
    dragToExplore: () => {
        // console.info('_____ dragToExplore');
        push({
            event: 'flp',
            eventCategory: 'flp',
            eventAction: 'genius banner',
            eventLabel: `drag to explore`,
        });
    },
    /**
     * FLP Video module
     */
    /**
     * Track user start open video
     */
    openedVideo: (videoTitle) => {
        // console.info('_____ openedVideo');
        push({
            event: 'flp',
            eventCategory: 'flp',
            eventAction: videoTitle,
            eventLabel: 'video',
        });
    },
    /**
     * Track user clicks on a designer label in
     * genius slider
     * @param designerName - designer name clicked
     */
    designerClick: (designerName) => {
        // console.info('_____ designerClick');
        push({
            event: 'flp',
            eventCategory: 'flp',
            eventAction: 'genius banner',
            eventLabel: designerName,
        });
    },
    /**
     * Technical Pages
     */
    /**
     * Track user who clicks on the technical page menu / video
     */
    techincalPageClick: (eventAction, eventLabel) => {
        // console.info('_____ techincalPageClick');
        push({
            event: 'technical page',
            eventCategory: 'technical page',
            eventAction: eventAction,
            eventLabel: eventLabel,
        });
    },
    /**
     * Module View
     */
    moduleView: (eventAction, eventLabel) => {
        // console.info('_____ moduleView');
        push({
            event: 'technical page',
            eventCategory: 'technical page',
            eventAction: eventAction,
            eventLabel: eventLabel,
        });
    },
    /**
     *  TPChangeSizeColor
     */
    TPChangeSizeColor: (eventAction, eventLabel) => {
        // console.info('_____ TPChangeSizeColor');
        push({
            event: 'technical page',
            eventCategory: 'technical page',
            eventAction: eventAction,
            eventLabel: eventLabel,
        });
    },
    /**
     * TP - productImpressions
     */
    productImpressionsTP: (products, region) => {
        var _a;
        // console.info('_____ productImpressionsTP');
        push({
            event: `productImpressionsTP`,
            ecommerce: {
                currencyCode: (_a = price(products[0])) === null || _a === void 0 ? void 0 : _a.currency,
                impressions: products.map((product, index) => {
                    var _a, _b;
                    return (Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: index }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: `${region}_grenoble_tp_${(_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension38.toLowerCase()}` }));
                }),
            },
        });
    },
    /**
     * TP - AddToBagTP
     */
    AddToBagTP: (product, list, size) => {
        var _a, _b, _c;
        // console.info('_____ AddToBagTP');
        push({
            event: 'AddToBagTP',
            ecommerce: {
                currencyCode: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.currency,
                add: {
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_b = price(product)) === null || _b === void 0 ? void 0 : _b.value, quantity: 1 }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { dimension36: size ? size : (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension36, dimension52: list }),
                    ],
                },
            },
        });
    },
    productClickTP: (product, position, list) => {
        var _a, _b, _c, _d;
        // console.info('_____ productClickTP');
        push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: list ? list : undefined },
                    products: [
                        Object.assign(Object.assign({ name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, position: position }, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { list: list ? list : undefined, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }),
                    ],
                },
            },
        });
    },
    /**
     * Search events
     */
    /**
     * Track user click on lens or focus input
     * in order to start search
     */
    startSearch: () => {
        // console.info('_____ startSearch');
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'search start',
        });
    },
    deleteSearch: () => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'delete search',
        });
    },
    closeSearch: () => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'close search',
        });
    },
    exploreSearch: (suggestionClicked) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'no results-click carousel',
            eventLabel: suggestionClicked === null || suggestionClicked === void 0 ? void 0 : suggestionClicked.toLowerCase(),
        });
    },
    quickShopNoResultsSearch: (product) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'no results-quick shop',
            eventLabel: product === null || product === void 0 ? void 0 : product.toLowerCase(),
        });
    },
    assistanceSearch: (ctaClicked) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'no results-contact assistance',
            eventLabel: ctaClicked === null || ctaClicked === void 0 ? void 0 : ctaClicked.toLowerCase(),
        });
    },
    collectionsSearch: (ctaClicked) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'no results-discover collections',
            eventLabel: ctaClicked === null || ctaClicked === void 0 ? void 0 : ctaClicked.toLowerCase(),
        });
    },
    suggestionSearch: (suggestionClicked) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'text suggestion',
            eventLabel: suggestionClicked === null || suggestionClicked === void 0 ? void 0 : suggestionClicked.toLowerCase(),
        });
    },
    quickShopSearch: (productId) => {
        // console.info('_____ quickShop');
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'quick shop',
            eventLabel: productId,
        });
    },
    vtoSearchGeneric: (productId) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'virtual try on',
            eventLabel: productId,
        });
    },
    /**
     * Track user clicks on recent/suggested/popular searches
     * @param groupCategory - column group where user click category link
     * @param categoryClicked - direct name which user clicked
     */
    suggestedSearchClick: (groupCategory, categoryClicked) => {
        // console.info('_____ suggestedSearchClick');
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: groupCategory,
            eventLabel: categoryClicked,
        });
    },
    /**
     * Track user clicks on a product shwon on the results area
     * @param modelFabric - id of product clicked
     * @param searchedWord - word searched
     */
    productSearchClickGeneric: (modelFabric, searchedWord) => {
        // console.info('_____ productSearchClickGeneric');
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: modelFabric,
            eventLabel: searchedWord,
        });
    },
    /**
     * Track user clicks on a suggestion visual card
     * after landing on a 'no results' search
     * @param suggestionClicked - name of suggestion clicked
     * @param searchedWord - word searched
     */
    searchVisualCardClick: (suggestionClicked, searchedWord) => {
        // console.info('_____ searchVisualCardClick');
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: 'no results',
            eventLabel: `${searchedWord}-${suggestionClicked}`,
        });
    },
    /**
     * Track search with and without results [OE-23137]
     * @param results - results | no results
     * @param searchedWord - word searched
     */
    searchResults: (results, searchedWord) => {
        push({
            event: 'search',
            eventCategory: 'search',
            eventAction: results,
            eventLabel: searchedWord,
        });
    },
    /**
     * Menu events
     */
    /**
     * Track user click on first level of menu (mobile/tablet)
     * @param firstLevelSection first level section text
     */
    firstLevelClick: (firstLevelSection) => {
        const dataLayer = {
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: firstLevelSection,
        };
        push(dataLayer);
        // console.info('_____ firstLevelClick', dataLayer);
    },
    /**
     * Track user click on second level of menu (mobile/tablet)
     * @param firstLevelSection first level section text
     * @param secondLevelSection second level section text
     */
    secondLevelClick: (firstLevelSection, secondLevelSection) => {
        const dataLayer = {
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: `${firstLevelSection}_${secondLevelSection}`,
        };
        push(dataLayer);
        // console.info('_____ secondLevelClick', dataLayer);
    },
    /**
     * Track user click on third level of menu (mobile/tablet)
     * @param firstLevelSection first level section text
     * @param secondLevelSection second level section text
     * @param thirdLevelSection third level section text
     */
    thirdLevelClick: (firstLevelSection, secondLevelSection, thirdLevelSection) => {
        // console.info('_____ thirdLevelClick');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: `${firstLevelSection}_${secondLevelSection}_${thirdLevelSection}`,
        });
    },
    /**
     * Track user click on fourth level of menu (mobile/tablet)
     * @param firstLevelSection first level section text
     * @param secondLevelSection second level section text
     * @param thirdLevelSection third level section text
     * @param fourthLevelSection third level section text
     */
    fourthLevelClick: (firstLevelSection, secondLevelSection, thirdLevelSection, fourthLevelSection) => {
        // console.info('_____ fourthLevelClick');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: `${firstLevelSection}_${secondLevelSection}_${thirdLevelSection}_${fourthLevelSection}`,
        });
    },
    /**
     * Track user go back from second level to the first with arrow button
     * @param firstLevelSection first level section text
     */
    backFromSecondLevel: (firstLevelSection) => {
        // console.info('_____ backFromSecondLevel');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: `${firstLevelSection}`,
            eventLabel: 'back',
        });
    },
    /**
     * Track user go back from third level to the second with arrow button
     * @param firstLevelSection first level section text
     * @param secondLevelSection second level section text
     */
    backFromThirdLevel: (firstLevelSection, secondLevelSection) => {
        // console.info('_____ backFromThirdLevel');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: `${firstLevelSection}-${secondLevelSection}`,
            eventLabel: 'back',
        });
    },
    /**
     * Track user close account menu
     */
    closeMyMonclerMenu: () => {
        // console.info('_____ closeMyMonclerMenu');
        push({
            event: 'menu',
            eventCategory: 'account menu',
            eventAction: 'my moncler',
            eventLabel: 'close',
        });
    },
    /**
     * Track user close menu from first level
     */
    closeMenuFromFirstLevel: () => {
        // console.info('_____ closeMenuFromFirstLevel');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: 'main',
            eventLabel: 'close',
        });
    },
    /**
     * Track user close menu from second level
     * @param secondLevelSection third level section text
     */
    closeMenuFromSecondLevel: (secondLevelSection) => {
        // console.info('_____ closeMenuFromSecondLevel');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: secondLevelSection,
            eventLabel: 'close',
        });
    },
    /**
     * Track user close menu from third level
     * @param secondLevelSection second level section text
     * @param thirdLevelSection third level section text
     */
    closeMenuFromThirdLevel: (secondLevelSection, thirdLevelSection) => {
        // console.info('_____ closeMenuFromThirdLevel');
        push({
            event: 'menu',
            eventCategory: 'nav menu',
            eventAction: `${secondLevelSection}-${thirdLevelSection}`,
            eventLabel: 'close',
        });
    },
    storelocatorSearch: (searchedTerm) => {
        // console.info('_____ storelocatorSearch');
        push({
            event: 'storelocator',
            eventCategory: 'storelocator',
            eventAction: 'search-boutique',
            eventLabel: searchedTerm,
        });
    },
    storelocatorMyLocation: () => {
        // console.info('_____ storelocatorMyLocation');
        push({
            event: 'storelocator',
            eventCategory: 'storelocator',
            eventAction: 'find-boutique',
            eventLabel: 'use my location',
        });
    },
    storelocatorSelect: (storeName) => {
        // console.info('_____ storelocatorSelect');
        push({
            event: 'storelocator',
            eventCategory: 'storelocator',
            eventAction: 'find-boutique',
            eventLabel: storeName,
        });
    },
    storelocatorAction: (storeName, label) => {
        // console.info('_____ storelocatorAction');
        push({
            event: 'storelocator',
            eventCategory: 'storelocator',
            eventAction: storeName,
            eventLabel: label,
        });
    },
    storelocatorBookAppointment: (event, label) => {
        // console.info('_____ storelocatorBookAppointment event: ' + event + ' label: ' + label);
        push({
            event: event,
            eventCategory: event,
            eventAction: 'book appointment',
            eventLabel: label,
        });
    },
    bookAppointmentSubmit: (label) => {
        // console.info('_____ bookAppointmentSubmit', label);
        push({
            event: 'pdp',
            eventCategory: 'pdp',
            eventAction: 'book an appointment-submit',
            eventLabel: label,
        });
    },
    storelocatorSearchNotFound: (searchedTerm) => {
        // console.info('_____ storelocatorSearchNotFound');
        push({
            event: 'storelocator',
            eventCategory: 'storelocator',
            eventAction: 'search-boutique-not found',
            eventLabel: searchedTerm,
        });
    },
    storelocatorOpenRefine: (filter) => {
        // console.info('_____ storelocatorOpenRefine');
        push({
            event: 'storelocator',
            eventCategory: 'refine_storelocator',
            eventAction: filter ? filter : 'open',
        });
    },
    storelocatorStoreCta: (name, cta) => {
        // console.info('_____ storelocatorStoreCta');
        push({
            event: 'storelocator',
            eventCategory: 'storelocator',
            eventAction: name,
            eventLabel: `detail-${cta}`,
        });
    },
    /* HOMEPAGE */
    /**
     *
     * Track user who click on the product on Lunettes module
     *
     * @param product product clicked object
     * @param position product clicked position
     */
    lunettesProductClick: (product, position) => {
        var _a, _b, _c, _d;
        // console.info('_____ lunettesProductClick');
        push({
            event: 'productClick',
            ecommerce: {
                click: {
                    actionField: { list: 'lunettes_homepage' },
                    products: [
                        Object.assign(Object.assign({}, product === null || product === void 0 ? void 0 : product.analyticsAttributes), { name: product === null || product === void 0 ? void 0 : product.originalModelName, id: product === null || product === void 0 ? void 0 : product.masterId, price: (_a = price(product)) === null || _a === void 0 ? void 0 : _a.value, category: 'eyewear', position: position, quantity: 1, dimension102: generateDim102(product), dimension104: (product === null || product === void 0 ? void 0 : product.VTOcode) ? true : false, dimension110: (_b = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _b === void 0 ? void 0 : _b.dimension61, dimension111: (_c = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _c === void 0 ? void 0 : _c.dimension56, dimension112: (_d = product === null || product === void 0 ? void 0 : product.analyticsAttributes) === null || _d === void 0 ? void 0 : _d.dimension112 }),
                    ],
                },
            },
        });
    },
    /** Top Ticker Guest/Logged
     */
    topTicker: (pageTitle, titleAnalytics, isRegisteredUser) => {
        push({
            event: pageTitle.toLowerCase(),
            eventCategory: pageTitle.toLowerCase(),
            eventAction: titleAnalytics.toLowerCase() + ' - ' + 'banner interactions',
            eventLabel: isRegisteredUser,
        });
    },
    /** Sticky Popup
     */
    stickyPopup: (pageTitle, titleAnalytics, buttonText) => {
        push({
            event: pageTitle.toLowerCase(),
            eventCategory: pageTitle.toLowerCase(),
            eventAction: titleAnalytics.toLowerCase() + '-' + 'popup interactions',
            eventLabel: buttonText.toLowerCase(),
        });
    },
    /** Service Bar Footer
     */
    serviceBarFooter: (pageTitle, titleAnalytics, isRegisteredUser) => {
        push({
            event: pageTitle.toLowerCase(),
            eventCategory: pageTitle.toLowerCase(),
            eventAction: titleAnalytics.toLowerCase() + '-' + 'popup interactions',
            eventLabel: isRegisteredUser,
        });
    },
    /** Membership banner module - Login Registration Popup
     */
    bannerModuleLoginRegistrationPopup: (titleAnalytics, buttonText) => {
        push({
            event: 'banner',
            eventCategory: 'banner',
            eventAction: titleAnalytics === null || titleAnalytics === void 0 ? void 0 : titleAnalytics.toLowerCase(),
            eventLabel: buttonText === null || buttonText === void 0 ? void 0 : buttonText.toLowerCase(),
        });
    },
    /**
     * Cookie consent tracking
     *
     * @param category selected flags
     */
    cookieConsent: (category) => {
        // console.info('_____ cookieConsent');
        push({
            event: 'cookiePolicy',
            eventCategory: category,
        });
    },
    /**
     *
     * track banners that user seen when land on HP/FLP
     *
     * @param promotions list of impression object
     */
    internalPromoView: (promotions) => {
        const promoImpress = {
            event: 'promotionView',
            ecommerce: {
                promoView: {
                    promotions: promotions,
                },
            },
        };
        push(promoImpress);
        // console.info('_____ internalPromoView', promoImpress);
    },
    /**
     *
     * track slider that user change on HP/FLP
     *
     * @param promotion promo changed
     */
    internalPromoChange: (promotion) => {
        // console.info('_____ internalPromoChange');
        push({
            event: 'promotionChange',
            ecommerce: {
                promoView: {
                    promotions: [promotion],
                },
            },
        });
    },
    /**
     *
     * track user click on HP/FLP banner/slider
     *
     * @param promotion promo clicked
     */
    internalPromoClick: (promotion) => {
        const promotionClick = {
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [promotion],
                },
            },
        };
        push(promotionClick);
        // console.info('_____ internalPromoClick', promotionClick);
    },
    internalBannerInteraction: (promotion, cta) => {
        const promotionClick = {
            event: promotion.creative,
            eventCategory: promotion.creative,
            eventAction: 'banner interaction',
            eventLabel: promotion.name + '-' + cta,
        };
        push(promotionClick);
        // console.info('_____ internalPromoClick', promotionClick);
    },
    header: (action, click) => {
        // console.info('_____ header');
        push({
            event: 'header',
            eventCategory: 'header',
            eventAction: action,
            eventLabel: click ? 'click' : undefined,
        });
    },
    colorSelection: (color, model, type) => {
        // console.info('_____ colorSelection');
        if (type == 'PLP') {
            push({
                event: 'plp',
                eventCategory: 'plp',
                eventAction: 'color selection',
                eventLabel: `${color === null || color === void 0 ? void 0 : color.toLowerCase()}-${model}`,
            });
        }
        else {
            push({
                event: 'pdp',
                eventCategory: 'pdp',
                eventAction: 'color selection',
                eventLabel: `${color}-${model}`,
            });
        }
    },
    videoImpression: () => {
        // console.info('_____ videoImpression');
        push({
            event: 'video shoppable',
            eventCategory: 'video shoppable',
            eventAction: 'video',
            eventLabel: 'view video',
        });
    },
    videoInteraction: (type) => {
        // console.info('_____ videoInteraction');
        push({
            event: 'video shoppable',
            eventCategory: 'video shoppable',
            eventAction: 'video',
            eventLabel: type,
        });
    },
    configuratorPageType: (type) => {
        // console.info('_____ configuratorPageType', type);
        push({
            event: 'configurator_vp',
            pageType: type,
        });
    },
    configuratorAction: (eventAction, eventLabel) => {
        // console.info('_____ configuratorAction eventAction: ' + eventAction + ' eventLabel: ' + eventLabel);
        push({
            event: 'configurator',
            eventCategory: 'configurator',
            eventAction: eventAction,
            eventLabel: eventLabel,
        });
    },
    /**
     *
     * Early Access
     *
     */
    earlyAccess: (action, label) => {
        push({
            event: 'flp',
            eventCategory: 'early access',
            eventAction: action,
            eventLabel: label,
        });
    },
    /**
     *
     * Wallet Connect
     *
     */
    walletConnect: (category, action, label) => {
        push(Object.assign(Object.assign(Object.assign({ event: 'flp' }, (category && { eventCategory: category })), (action && { eventAction: action })), (label && { eventLabel: label })));
    },
    sidebarBanner: (eventLabel) => {
        push({
            event: 'menu',
            eventCategory: 'account menu',
            aventAction: 'banner',
            eventLabel: eventLabel,
        });
    },
    retargetingFlagOn: () => {
        push({
            event: 'generic_checkout_consentbox',
            eventCategory: 'abandoned Cart ',
            eventAction: 'flag-on',
        });
    },
};
export default analytics;
