/* eslint-disable react/display-name */
__webpack_public_path__ = `${window.chunkspath || document.documentElement.dataset.publicpath}`;
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import '@scripts/static';
import './scss/main.scss';
import '@utils/i18n';
import { registerComponent, initReactApps, registerDefaultComponents } from '@common/loader';
import * as Sentry from '@sentry/react';
// import SASS
import { Integrations } from '@sentry/tracing';
import analytics from '@utils/analytics';
import webpsupport from '@utils/webpsupport';
import avifsupport from '@utils/avifsupport';
import React from 'react';
import cookie from 'react-cookies';
import BreakpointerService from '@common/scripts/services/breakpointerService';
import { ResolutionHandler } from '@common/scripts/utilities/resolutionHandler';
ResolutionHandler();
new BreakpointerService();
//istance of what workspace is
window.brand = 'moncler';
window.brandId = 'MO';
// instanzio la gestione del gtm su window
window.analytics = analytics;
if ('undefined' === typeof window._etmc) {
    window._etmc = []; // avoid errors for late instantiation of marketing cloud collect code
}
// Map Enviroment as described in OE-13892
let sentryEnvironment = 'moncweb-dev';
if (window.environment === 'production') {
    sentryEnvironment = 'moncweb-prod';
}
else if (window.environment === 'staging') {
    sentryEnvironment = 'moncweb-stag';
}
// development, sandbox
// prevent sentry on dev sandboxes
if (window.location.hostname.indexOf('dev') === -1 && !window.disableAnalytics) {
    if (window.sentryDsn && window.sentrySampleRate) {
        Sentry.init({
            dsn: window.sentryDsn,
            environment: (window.ssr ? 'ssr-' : '') + sentryEnvironment,
            release: window.release,
            autoSessionTracking: true,
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: window.sentrySampleRate,
            replaysSessionSampleRate: window.sentryReplaysSessionSampleRate,
            replaysOnErrorSampleRate: window.sentryReplaysOnErrorSampleRate,
            // This enables automatic instrumentation (highly recommended), but is not
            // necessary for purely manual usage
            integrations: [new Integrations.BrowserTracing()],
        });
    }
    // Sentry.init({
    //   dsn: 'https://d4fd99cefa4741af819754d0a79d3d82@o1202846.ingest.sentry.io/6568583',
    //   environment: (window.ssr ? 'ssr-' : '') + window.location.hostname.substr(0, window.location.hostname.indexOf('.')),
    //   release: window.release,
    //   autoSessionTracking: true,
    //   // We recommend adjusting this value in production, or using tracesSampler
    //   // for finer control
    //   tracesSampleRate: 0.9,
    //   // This enables automatic instrumentation (highly recommended), but is not
    //   // necessary for purely manual usage
    //   integrations: [new Integrations.BrowserTracing()],
    // });
}
// window.OneTrustDisableLock is false by default, enable it to avoid scroll locking
if (window.cookiePolicy === 'OneTrust') {
    if (window.OneTrust && !window.OneTrust.IsAlertBoxClosed() && !window.OneTrustDisableLock) {
        document.body.classList.add('no-overflow-container');
    }
    window.addEventListener('onetrust.changed', function () {
        document.body.classList.remove('no-overflow-container');
        const encodedURI = encodeURIComponent(window.OnetrustActiveGroups);
        fetch(window.homeurl + '/consent/onetrust?optanonag=' + encodedURI)
            .then((res) => res.json())
            .then((response) => {
            console.log(response);
            cookie.save('OneTrustChoices', JSON.stringify(response), { path: '/' });
            window.dispatchEvent(new CustomEvent('OneTrust:changed', { detail: response }));
        })
            .catch((err) => {
            if (typeof err == 'string') {
                console.log(JSON.parse(err));
            }
            else {
                console.error('Consent url not found');
            }
        });
    });
}
// per gestione transizione PDP/PLP
if (window.history.scrollRestoration) {
    window.history.scrollRestoration = 'manual';
}
webpsupport.then((supported) => {
    window.webpsupported = supported;
});
avifsupport.then((supported) => {
    window.avifsupported = supported;
});
const registerMonclerComponents = () => {
    const MiniBag = React.lazy(() => import(/* webpackChunkName: "component-minicart" */ './components/minibag/MiniBagLoader'));
    registerComponent('minicart-app', (attrs) => React.createElement(MiniBag, Object.assign({}, attrs)));
    const Bag = React.lazy(() => import(/* webpackChunkName: "component-bag" */ './components/bag/BagLoader'));
    registerComponent('cart-app', (attrs) => React.createElement(Bag, Object.assign({}, attrs)));
    const ExpressPaymentsButtonsApp = React.lazy(() => import(/* webpackChunkName: "component-expressbuttons" */ '@components/bag/ExpressPaymentsButtonsApp'));
    registerComponent('checkoutexpressbuttons-app', (attrs) => React.createElement(ExpressPaymentsButtonsApp, Object.assign({}, attrs)));
    const Catalog = React.lazy(() => import(/* webpackChunkName: "component-catalog" */ './components/listing/Catalog'));
    registerComponent('catalog-app', (attrs) => React.createElement(Catalog, Object.assign({}, attrs)));
    const CollectionIntroHeader = React.lazy(() => import(/* webpackChunkName: "component-collectionintroheader" */ './components/experience/CollectionIntroHeader'));
    registerComponent('collection-intro-header', (attrs) => React.createElement(CollectionIntroHeader, Object.assign({}, attrs)));
    const SearchApp = React.lazy(() => import(/* webpackChunkName: "component-search-app" */ './components/search/SearchApp'));
    registerComponent('search-app', (attrs) => React.createElement(SearchApp, Object.assign({}, attrs)));
    const CountryModal = React.lazy(() => import(/* webpackChunkName: "component-countryselector" */ './components/countryselector/CountryModal'));
    registerComponent('countryselector', (attrs) => React.createElement(CountryModal, Object.assign({}, attrs)));
    const LanguageModal = React.lazy(() => import(/* webpackChunkName: "component-languageselector" */ './components/countryselector/LanguageModal'));
    registerComponent('languageselector', (attrs) => React.createElement(LanguageModal, Object.assign({}, attrs)));
    const CountDownHero = React.lazy(() => import(/* webpackChunkName: "component-countdown" */ './components/countdown/countdown'));
    registerComponent('countdown-app', (attrs) => React.createElement(CountDownHero, Object.assign({}, attrs)));
    const ProductTileApp = React.lazy(() => import(/* webpackChunkName: "component-productTile" */ './components/listing/ProductTile'));
    registerComponent('product-tile', (attrs) => React.createElement(ProductTileApp, Object.assign({}, attrs)));
    const ProductTileFeaturedApp = React.lazy(() => import(/* webpackChunkName: "component-productTileFeatured" */ './components/listing/ProductTileFeatured'));
    registerComponent('product-tile-featured', (attrs) => React.createElement(ProductTileFeaturedApp, Object.assign({}, attrs)));
    const PromoEditorialCard = React.lazy(() => import(/* webpackChunkName: "component-promoEditorialCard" */ './components/listing/PromoEditorialCard'));
    registerComponent('promo-editorial-card', (attrs) => React.createElement(PromoEditorialCard, Object.assign({}, attrs)));
    const Promo5050 = React.lazy(() => import(/* webpackChunkName: "component-promo5050" */ './components/listing/Promo5050'));
    registerComponent('promo-50-50', (attrs) => React.createElement(Promo5050, Object.assign({}, attrs)));
    const DownloadApp = React.lazy(() => import(/* webpackChunkName: "component-downloadapp" */ './components/listing/DownloadApp'));
    registerComponent('download-app', (attrs) => React.createElement(DownloadApp, Object.assign({}, attrs)));
    const PromoShowcase = React.lazy(() => import(/* webpackChunkName: "component-promoShowcase" */ './components/listing/PromoShowcase'));
    registerComponent('promo-showcase', (attrs) => React.createElement(PromoShowcase, Object.assign({}, attrs)));
    const PromoShowcaseCard = React.lazy(() => import(/* webpackChunkName: "component-promoShowcaseCard" */ './components/listing/PromoShowcaseCard'));
    registerComponent('promo-showcase-card', (attrs) => React.createElement(PromoShowcaseCard, Object.assign({}, attrs)));
    const RichVideo = React.lazy(() => import(/* webpackChunkName: "component-richVideo" */ './components/listing/RichVideo'));
    registerComponent('rich-video', (attrs) => React.createElement(RichVideo, Object.assign({}, attrs)));
    const EditorialText = React.lazy(() => import(/* webpackChunkName: "component-editorialText" */ './components/listing/EditorialText'));
    registerComponent('editorial-text', (attrs) => React.createElement(EditorialText, Object.assign({}, attrs)));
    const FullyEditorialPlpPageWrapper = React.lazy(() => import(/* webpackChunkName: "component-fullyEditorialPlpPageWrapper" */ './components/listing/FullyEditorialPlpPageWrapper'));
    registerComponent('fully-editorial-plp-page-wrapper', (attrs) => React.createElement(FullyEditorialPlpPageWrapper, Object.assign({}, attrs)));
    const MonclerNowPortalApp = React.lazy(() => import(/* webpackChunkName: "component-monclernowportal" */ './components/monclernow/Portal'));
    registerComponent('monclernow-portal', (attrs) => React.createElement(MonclerNowPortalApp, Object.assign({}, attrs)));
    const MonclernowBottomModule = React.lazy(() => import(/* webpackChunkName: "component-monclernowarticlebottommodule" */ './components/monclernow/MonclernowBottomModule'));
    registerComponent('monclernow-bottommodule', (attrs) => React.createElement(MonclernowBottomModule, Object.assign({}, attrs)));
    const TechnicalPage = React.lazy(() => import(/* webpackChunkName: "component-technicalpage" */ './components/grenoble/TechnicalPage'));
    registerComponent('technical-page-app', (attrs) => React.createElement(TechnicalPage, Object.assign({}, attrs)), true);
    const RecommendedListing = React.lazy(() => import(/* webpackChunkName: "component-recommended-listing" */ './components/listing/RecommendedListing'));
    registerComponent('ai-plp-app', (attrs) => React.createElement(RecommendedListing, Object.assign({}, attrs)));
    const CuratorsListing = React.lazy(() => import(/* webpackChunkName: "component-curators-listing" */ './components/curators/CuratorsListing'));
    registerComponent('curators-listing', (attrs) => React.createElement(CuratorsListing, Object.assign({}, attrs)));
    const ProductRangeCarousel = React.lazy(() => import(/* webpackChunkName: "component-monclernowportal" */ './components/grenoble/ProductRangeCarousel'));
    registerComponent('product-range-carousel', (attrs) => React.createElement(ProductRangeCarousel, Object.assign({}, attrs)));
    const CuratorCarousel = React.lazy(() => import(/* webpackChunkName: "component-curator-carousel" */ './components/curators/CuratorCarousel'));
    registerComponent('curator-carousel', (attrs) => React.createElement(CuratorCarousel, Object.assign({}, attrs)));
    const CuratorNewsletter = React.lazy(() => import(/* webpackChunkName: "component-curator-newsletter" */ './components/curators/CuratorNewsletter'));
    registerComponent('curator-newsletter', (attrs) => React.createElement(CuratorNewsletter, Object.assign({}, attrs)));
    const ContainerShopTheLook = React.lazy(() => import(/* webpackChunkName: "component-shopthelook" */ './components/listing/ContainerShopTheLook'));
    registerComponent('container-shop-the-look', (attrs) => React.createElement(ContainerShopTheLook, Object.assign({}, attrs)));
    const ThemedProducts = React.lazy(() => import(/* webpackChunkName: "component-themedproducts" */ './components/collections/ThemedProductsSimplified'));
    registerComponent('themed-products', (attrs) => React.createElement(ThemedProductsSimplified, Object.assign({}, attrs)));
    const ThemedProductsSimplified = React.lazy(() => import(/* webpackChunkName: "component-themedproducts-simplified" */ './components/collections/ThemedProducts'));
    registerComponent('themed-products-simplified', (attrs) => React.createElement(ThemedProducts, Object.assign({}, attrs)));
    const BenefitsCarousel = React.lazy(() => import(/* webpackChunkName: "component-themedproducts" */ './components/account/myFeed/components/BenefitsSection'));
    registerComponent('benefits-carousel', (attrs) => React.createElement(BenefitsCarousel, Object.assign({}, attrs)));
    const ExclusiveProducts = React.lazy(() => import(/* webpackChunkName: "component-themedproducts" */ './components/collections/ExclusiveProducts'));
    registerComponent('exclusive-products', (attrs) => React.createElement(ExclusiveProducts, Object.assign({}, attrs)));
    const SlimBanner = React.lazy(() => import(/* webpackChunkName: "component-slimBanner" */ './components/listing/SlimBanner'));
    registerComponent('slim-banner', (attrs) => React.createElement(SlimBanner, Object.assign({}, attrs)));
    const StoriesCarousel = React.lazy(() => import(/* webpackChunkName: "component-storiescarousel" */ './components/collections/StoriesCarousel'));
    registerComponent('stories-carousel', (attrs) => React.createElement(StoriesCarousel, Object.assign({}, attrs)));
    const StoriesCarouselSimplified = React.lazy(() => import(/* webpackChunkName: "component-storiescarousel-simplified" */ './components/collections/StoriesCarouselSimplified'));
    registerComponent('stories-carousel-simplified', (attrs) => React.createElement(StoriesCarouselSimplified, Object.assign({}, attrs)));
    const InteractiveCards = React.lazy(() => import(/* webpackChunkName: "component-interactivecards" */ './components/collections/InteractiveCards'));
    registerComponent('interactive-cards', (attrs) => React.createElement(InteractiveCards, Object.assign({}, attrs)));
    const Wishlist = React.lazy(() => import(/* webpackChunkName: "component-wishlist" */ './components/wishlist/Wishlist'));
    registerComponent('wishlist-app', (attrs) => React.createElement(Wishlist, Object.assign({}, attrs)));
    const NewsletterApp = React.lazy(() => import(/* webpackChunkName: "component-newsletterapp" */ './components/newsletter/Newsletter'));
    registerComponent('newsletter-app', (attrs) => React.createElement(NewsletterApp, Object.assign({}, attrs)));
    const NewsletterPopupApp = React.lazy(() => import(/* webpackChunkName: "component-newsletterpopupapp" */ './components/newsletter/NewsletterPopup'));
    registerComponent('newsletterpopup-app', (attrs) => React.createElement(NewsletterPopupApp, Object.assign({}, attrs)));
    const StickyPopupApp = React.lazy(() => import(/* webpackChunkName: "component-stickypopupapp" */ './components/newsletter/StickyPopup'));
    registerComponent('stickypopup-app', (attrs) => React.createElement(StickyPopupApp, Object.assign({}, attrs)));
    const NewsletterOptinConfirmModal = React.lazy(() => import(/* webpackChunkName: "component-newsletteroptin" */ './components/newsletter/NewsletterOptinConfirmModal'));
    registerComponent('newsletteroptin', (attrs) => React.createElement(NewsletterOptinConfirmModal, Object.assign({}, attrs)));
    const ClothingDetails = React.lazy(() => import(/* webpackChunkName: "component-myaccount-clothing-details" */ './components/clothing/Details'));
    registerComponent('myaccount-clothing-details', (attrs) => React.createElement(ClothingDetails, Object.assign({}, attrs)));
    const Checkout = React.lazy(() => import(/* webpackChunkName: "component-checkout" */ '@components/checkout/Checkout'));
    registerComponent('checkout-app', (attrs) => React.createElement(Checkout, Object.assign({}, attrs)));
    const TestMoncler = React.lazy(() => import(/* webpackChunkName: "component-test-moncler" */ './components/test/TestMoncler'));
    registerComponent('test-moncler', (attrs) => React.createElement(TestMoncler, Object.assign({}, attrs)));
    const SpecialSale = React.lazy(() => import(/* webpackChunkName: "special-sale" */ '@moncler/components/specialsale/SpecialSale'));
    registerComponent('special-sale-app', (attrs) => React.createElement(SpecialSale, Object.assign({}, attrs)));
    const AccountMenu = React.lazy(() => import(/* webpackChunkName: "component-account-menu" */ '@moncler/components/menu/AccountMenu'));
    registerComponent('account-menu', (attrs) => React.createElement(AccountMenu, Object.assign({}, attrs)));
    const AccountMenuDesktop = React.lazy(() => import(/* webpackChunkName: "component-account-menu-desktop" */ '@moncler/components/menu/AccountMenuDesktop'));
    registerComponent('account-menu-desktop', (attrs) => React.createElement(AccountMenuDesktop, Object.assign({}, attrs)));
    const AccountMenuMobile = React.lazy(() => import(/* webpackChunkName: "component-account-menu-mobile" */ '@moncler/components/menu/AccountMenuMobile'));
    registerComponent('account-menu-mobile', (attrs) => React.createElement(AccountMenuMobile, Object.assign({}, attrs)));
    const WishlistButton = React.lazy(() => import(/* webpackChunkName: "component-account-menu-mobile" */ '@moncler/components/menu/WishlistButton'));
    registerComponent('wishlist-button', (attrs) => React.createElement(WishlistButton, Object.assign({}, attrs)));
    const MyAccountProfile = React.lazy(() => import(/* webpackChunkName: "component-myaccountprofile" */ '@moncler/components/account/MyAccountProfileLoader'));
    registerComponent('myaccount-profile-moncler-app', (attrs) => React.createElement(MyAccountProfile, Object.assign({}, attrs)));
    const MyFeedPage = React.lazy(() => import(/* webpackChunkName: "component-myaccountprofile" */ '@moncler/components/account/myFeed/MyFeedPage'));
    registerComponent('my-feed', (attrs) => React.createElement(MyFeedPage, Object.assign({}, attrs)));
    const AccountOrdersHistory = React.lazy(() => import(/* webpackChunkName: "component-myaccountordershistory" */ './components/account/AccountOrdersHistoryLoader'));
    registerComponent('myaccount-orders-app', (attrs) => React.createElement(AccountOrdersHistory, Object.assign({}, attrs)));
    const OrderDetailPage = React.lazy(() => import(/* webpackChunkName: "component-orderdetail" */ './components/order/OrderDetailPage'));
    registerComponent('component-orderdetail', (attrs) => React.createElement(OrderDetailPage, Object.assign({}, attrs)));
    const OrderDetailPageLoader = React.lazy(() => import(/* webpackChunkName: "component-orderdetailhistory" */ './components/order/OrderDetailPageLoader'));
    registerComponent('orderdetail', (attrs) => React.createElement(OrderDetailPageLoader, Object.assign({}, attrs)));
    const ReturnDetail = React.lazy(() => import(/* webpackChunkName: "component-returndetail" */ './components/returndetail/ReturnDetail'));
    registerComponent('returndetail', (attrs) => React.createElement(ReturnDetail, Object.assign({}, attrs)));
    const ProductRecommendationApp = React.lazy(() => import(/* webpackChunkName: "component-recommendation" */ './components/product/ProductRecommendationLoader'));
    registerComponent('recommendations-app', (attrs) => React.createElement(ProductRecommendationApp, Object.assign({}, attrs)));
    const ProductCarouselFlpRecommendedApp = React.lazy(() => import(/* webpackChunkName: "component-recommendationFLP" */ './components/product/ProductCarouselFlpRecommended'));
    registerComponent('recommendationscarousel-app', (attrs) => React.createElement(ProductCarouselFlpRecommendedApp, Object.assign({}, attrs)));
    const ConfiguratorEvents = React.lazy(() => import(/* webpackChunkName: "configurator-events" */ './components/mbm/ConfiguratorEvents'));
    registerComponent('configurator-events', (attrs) => React.createElement(ConfiguratorEvents, Object.assign({}, attrs)));
    const MbmConfigurator = React.lazy(() => import(/* webpackChunkName: "mbm-configurator" */ './components/mbm/MbmConfigurator'));
    registerComponent('mbm-configurator', (attrs) => React.createElement(MbmConfigurator, Object.assign({}, attrs)));
    const MbmNotifyMe = React.lazy(() => import(/* webpackChunkName: "component-mbm-notifyme" */ './components/mbm/MbmNotifyMe'));
    registerComponent('mbm-notifyme', (attrs) => React.createElement(MbmNotifyMe, Object.assign({}, attrs)));
    const VtoCamera = React.lazy(() => import(/* webpackChunkName: "component-vto-camera" */ './components/vto/VtoCamera'));
    registerComponent('vto-camera', (attrs) => React.createElement(VtoCamera, Object.assign({}, attrs)));
    const ReturnFormPageLoader = React.lazy(() => import(/* webpackChunkName: "component-clientservicereturns" */ './components/clientService/ReturnFormPageLoader'));
    registerComponent('client-service-returns', (attrs) => React.createElement(ReturnFormPageLoader, Object.assign({}, attrs)));
    const ClientServiceExchange = React.lazy(() => import(/* webpackChunkName: "component-clientserviceexchange" */ './components/clientService/ClientServiceExchangeLoader'));
    registerComponent('client-service-exchange', (attrs) => React.createElement(ClientServiceExchange, Object.assign({}, attrs)));
    const ClientServiceTracking = React.lazy(() => import(/* webpackChunkName: "component-clientservicetracking" */ './components/clientService/ClientServiceTrackingLoader'));
    registerComponent('client-service-tracking', (attrs) => React.createElement(ClientServiceTracking, Object.assign({}, attrs)));
    const ClientServiceContactUs = React.lazy(() => import(/* webpackChunkName: "component-clientservicecontactus" */ './components/clientService/ClientServiceContactUsLoader'));
    registerComponent('client-service-contactus', (attrs) => React.createElement(ClientServiceContactUs, Object.assign({}, attrs)));
    const ClientServiceAftercare = React.lazy(() => import(/* webpackChunkName: "component-clientserviceaftercare" */ './components/aftercare/AftercareLoader'));
    registerComponent('client-service-aftercare', (attrs) => React.createElement(ClientServiceAftercare, Object.assign({}, attrs)));
    const ClientServiceLanding = React.lazy(() => import(/* webpackChunkName: "component-clientservicelanding" */ './components/clientService/ClientServiceLandingLoader'));
    registerComponent('client-service-landing', (attrs) => React.createElement(ClientServiceLanding, Object.assign({}, attrs)));
    const FaqSearchInput = React.lazy(() => import(/* webpackChunkName: "component-faqsearchinput" */ './components/clientService/FaqSearchInput'));
    registerComponent('faq-search-input', (attrs) => React.createElement(FaqSearchInput, Object.assign({}, attrs)));
    const FaqResults = React.lazy(() => import(/* webpackChunkName: "component-faqsearch" */ './components/clientService/FaqResults'));
    registerComponent('faq-results', (attrs) => React.createElement(FaqResults, Object.assign({}, attrs)));
    const ContactDetails = React.lazy(() => import(/* webpackChunkName: "component-contactdetails" */ './components/clientService/ContactDetails'));
    registerComponent('contact-details', (attrs) => React.createElement(ContactDetails, Object.assign({}, attrs)));
    const ContactDetailsAlternate = React.lazy(() => import(/* webpackChunkName: "component-contactdetailsalternate" */ './components/clientService/ContactDetailsAlternate'));
    registerComponent('contact-details-alternate', (attrs) => React.createElement(ContactDetailsAlternate, Object.assign({}, attrs)));
    const ContactDetailsFooter = React.lazy(() => import(/* webpackChunkName: "component-contactdetailsfooter" */ './components/clientService/ContactDetailsFooter'));
    registerComponent('contact-details-footer', (attrs) => React.createElement(ContactDetailsFooter, Object.assign({}, attrs)));
    const ContactDetailsEnhanced = React.lazy(() => import(/* webpackChunkName: "component-contactdetailsenhanced" */ './components/clientService/ContactDetailsEnhanced'));
    registerComponent('contact-details-enhanced', (attrs) => React.createElement(ContactDetailsEnhanced, Object.assign({}, attrs)));
    const FooterItemCustom = React.lazy(() => import(/* webpackChunkName: "component-cookiecenterlink" */ './components/footer/CookieCenterLink'));
    registerComponent('cookie-center-link', (attrs) => React.createElement(FooterItemCustom, Object.assign({}, attrs)));
    const AppointmentsPage = React.lazy(() => import(/* webpackChunkName: "component-appointment-page" */ './components/appointments/AppointmentsPage'));
    registerComponent('appointmentlist', (attrs) => React.createElement(AppointmentsPage, Object.assign({}, attrs)));
    const ManageAppointmentPage = React.lazy(() => import(/* webpackChunkName: "component-clientservice-manageappointment" */ './components/appointments/ManageAppointmentPage'));
    registerComponent('clientservice-manageappointment', (attrs) => React.createElement(ManageAppointmentPage, Object.assign({}, attrs)));
    const BookAnAppointmentPage = React.lazy(() => import(/* webpackChunkName: "component-clientservice-bookanappointment" */ './components/appointments/BookAnAppointmentPage'));
    registerComponent('clientservice-bookanappointment', (attrs) => React.createElement(BookAnAppointmentPage, Object.assign({}, attrs)));
    const BookAnAppointmentPageTrebaseleghe = React.lazy(() => import(/* webpackChunkName: "component-bookanappointmenttrebaseleghe" */ './components/appointments/BookAnAppointmentPageTrebaseleghe'));
    registerComponent('clientservice-bookanappointment-trebaseleghe', (attrs) => React.createElement(BookAnAppointmentPageTrebaseleghe, Object.assign({}, attrs)));
    const LiveBoutique = React.lazy(() => import(/* webpackChunkName: "component-clientservice-liveboutique" */ './components/appointments/LiveBoutique'));
    registerComponent('clientservice-liveboutique', (attrs) => React.createElement(LiveBoutique, Object.assign({}, attrs)));
    const NftWallet = React.lazy(() => import(/* webpackChunkName: "component-nft-wallet" */ './components/nft/NftWallet'));
    registerComponent('clientservice-nftwallet', (attrs) => React.createElement(NftWallet, Object.assign({}, attrs)));
    const WalletConnectModal = React.lazy(() => import(/* webpackChunkName: "component-wallet-connect-modal" */ './components/nft/WalletConnectModal'));
    registerComponent('wallet-connect-modal', (attrs) => React.createElement(WalletConnectModal, Object.assign({}, attrs)));
    const WalletConnectBtn = React.lazy(() => import(/* webpackChunkName: "component-wallet-connect-btn" */ './components/nft/WalletConnectBtn'));
    registerComponent('wallet-connect-btn', (attrs) => React.createElement(WalletConnectBtn, Object.assign({}, attrs)));
    const OnFirstLoginModal = React.lazy(() => import(/* webpackChunkName: "component-on-first-login-modal" */ './components/earlyaccess/OnFirstLoginModal'));
    registerComponent('on-first-login-modal', (attrs) => React.createElement(OnFirstLoginModal, Object.assign({}, attrs)));
    const TfnForAllCountries = React.lazy(() => import(/* webpackChunkName: "component-tfn-for-all-countries" */ './components/clientService/TfnForAllCountries'));
    registerComponent('remote-call-app', (attrs) => React.createElement(TfnForAllCountries, Object.assign({}, attrs)));
    const LoginModal = React.lazy(() => import(/* webpackChunkName: "component-login-modal" */ './components/login/LoginModal'));
    registerComponent('login-modal', (attrs) => React.createElement(LoginModal, Object.assign({}, attrs)));
    const TraceabilityPackaging = React.lazy(() => import(/* webpackChunkName: "component-traceabilitypackaging" */ './components/product/TraceabilityPackaging'));
    registerComponent('traceability-packaging', (attrs) => React.createElement(TraceabilityPackaging, Object.assign({}, attrs)));
    const MyAccountPreferences = React.lazy(() => import(/* webpackChunkName: "component-myaccountpreferences" */ './components/account/MyAccountPreferencesLoader'));
    registerComponent('myaccount-preferences-app', (attrs) => React.createElement(MyAccountPreferences, Object.assign({}, attrs)));
    const RegisterPage = React.lazy(() => import(/* webpackChunkName: "component-register" */ '@components/account/RegisterPage'));
    registerComponent('register-app', (attrs) => React.createElement(RegisterPage, Object.assign({}, attrs)));
    const Login = React.lazy(() => import(/* webpackChunkName: "component-login" */ '@components/login/LoginOrRegisterPage'));
    registerComponent('login-app', (attrs) => React.createElement(Login, Object.assign({}, attrs)));
    const AccountForgotPasswordStep1 = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step1" */ '@components/account/AccountForgotPasswordStep1Loader'));
    registerComponent('forgotpasswordstep1-app', (attrs) => React.createElement(AccountForgotPasswordStep1, Object.assign({}, attrs)));
    const AccountForgotPasswordStep2 = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step2" */ '@components/account/AccountForgotPasswordStep2Loader'));
    registerComponent('forgotpasswordstep2-app', (attrs) => React.createElement(AccountForgotPasswordStep2, Object.assign({}, attrs)));
    const AccountOrdersAfterCare = React.lazy(() => import(/* webpackChunkName: "component-myaccountordersaftercare" */ '@common/components/account/AccountAfterCareLoader'));
    registerComponent('myaccount-aftercare-app', (attrs) => React.createElement(AccountOrdersAfterCare, Object.assign({}, attrs)));
    const AccountOrdersReturns = React.lazy(() => import(/* webpackChunkName: "component-myaccountorderreturns" */ '@common/components/account/AccountOrdersReturnsLoader'));
    registerComponent('myaccount-orders-returns', (attrs) => React.createElement(AccountOrdersReturns, Object.assign({}, attrs)));
    const NewReturnPageLoader = React.lazy(() => import(/* webpackChunkName: "component-newreturn" */ '@components/returns/NewReturnPageLoader'));
    registerComponent('newreturn', (attrs) => React.createElement(NewReturnPageLoader, Object.assign({}, attrs)));
    const NewExchangePageLoader = React.lazy(() => import(/* webpackChunkName: "component-newexchange" */ '@components/exchange/NewExchangePageLoader'));
    registerComponent('newexchange', (attrs) => React.createElement(NewExchangePageLoader, Object.assign({}, attrs)));
    const ExchangeInBoutique = React.lazy(() => import(/* webpackChunkName: "component-exchangeinboutique" */ '@components/exchange/ExchangeInBoutique'));
    registerComponent('exchangeinboutique', (attrs) => React.createElement(ExchangeInBoutique, Object.assign({}, attrs)));
    const ReturnHowto = React.lazy(() => import(/* webpackChunkName: "component-returnHowto" */ '@components/returns/ReturnHowto'));
    registerComponent('app-returnhowto', (attrs) => React.createElement(ReturnHowto, Object.assign({}, attrs)));
    const SupportRow = React.lazy(() => import(/* webpackChunkName: "component-traceabilitypackaging" */ './components/account/support/SupportRow'));
    registerComponent('support-row', (attrs) => React.createElement(SupportRow, Object.assign({}, attrs)));
    const MyFeedHighlights = React.lazy(() => import(/* webpackChunkName: "component-traceabilitypackaging" */ './components/account/myFeed/highlights/MyFeedHighlights'));
    registerComponent('feed-highlights', (attrs) => React.createElement(MyFeedHighlights, Object.assign({}, attrs)));
    const AftercareDetailPage = React.lazy(() => import(/* webpackChunkName: "component-aftercaredetail" */ '@components/order/AftercareDetailPage'));
    registerComponent('component-aftercaredetail', (attrs) => React.createElement(AftercareDetailPage, Object.assign({}, attrs)));
    const DiscoveryGallery = React.lazy(() => import(/* webpackChunkName: "component-traceabilitypackaging" */ './components/discovery/discoveryGallery'));
    registerComponent('discovery-gallery', (attrs) => React.createElement(DiscoveryGallery, Object.assign({}, attrs)));
    const ProductsCarouselSimplified = React.lazy(() => import(/* webpackChunkName: "component-productcarousel-simplified" */ './components/collections/ProductsCarouselSimplified'));
    registerComponent('products-carousel-simplified', (attrs) => React.createElement(ProductsCarouselSimplified, Object.assign({}, attrs)));
    const Footer = React.lazy(() => import(/* webpackChunkName: "component-footer" */ './components/footer/Footer'));
    registerComponent('footer', (attrs) => React.createElement(Footer, Object.assign({}, attrs)));
    const EditorialVideo = React.lazy(() => import(/* webpackChunkName: "component-editorial-video" */ './components/experience/editorialVideo'));
    registerComponent('editorial-video', (attrs) => React.createElement(EditorialVideo, Object.assign({}, attrs)));
    const HeroCarousel = React.lazy(() => import(/* webpackChunkName: "component-hero-carousel" */ './components/experience/HeroCarousel'));
    registerComponent('hero-carousel', (attrs) => React.createElement(HeroCarousel, Object.assign({}, attrs)));
    const Hero = React.lazy(() => import(/* webpackChunkName: "component-hero-carousel" */ './components/experience/Hero'));
    registerComponent('hero', (attrs) => React.createElement(Hero, Object.assign({}, attrs)));
    const PromoModule = React.lazy(() => import(/* webpackChunkName: "component-promo-module" */ './components/experience/PromoModule'));
    registerComponent('promo-module', (attrs) => React.createElement(PromoModule, Object.assign({}, attrs)));
    const CollectionInpageNavigation = React.lazy(() => import(/* webpackChunkName: "component-promo-module" */ './components/experience/CollectionInpageNavigation'));
    registerComponent('collection-inpage-navigation', (attrs) => React.createElement(CollectionInpageNavigation, Object.assign({}, attrs)));
    const ProductPageEdit = React.lazy(() => import(/* webpackChunkName: "component-product-page-edit" */ './components/product/experience/ProductPageEdit'));
    registerComponent('product-page-edit', (attrs) => React.createElement(ProductPageEdit, Object.assign({}, attrs)));
};
registerDefaultComponents();
registerMonclerComponents();
initReactApps();
